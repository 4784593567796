import type { ConfigActionType } from '../actions/ConfigActions'
import { ConfigAction } from '../constants/ConfigAction'

type ConfigStateType = {
  error: string | null,
  loading: boolean,
  options: any,
  plans: any,
  products: any,
  engines: any,
}

const initialState: ConfigStateType = {
  error: null,
  loading: false,
  options: {},
  plans: {},
  products: {},
  engines: {},
}

const brandsReducer = (
  state: ConfigStateType = initialState,
  action: ConfigActionType
): ConfigStateType => {
  switch (action.type) {
    case ConfigAction.CLEAR:
      return initialState
    case ConfigAction.GET:
      return {
        ...state,
        loading: true,
      }
    case ConfigAction.GET_FAILED:
      return {
        ...state,
        loading: false,
        error: 'get configuration failed',
      }
    case ConfigAction.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.data,
      }
    default:
      return state
  }
}

export default brandsReducer

export type ConfigState = ConfigStateType