import { CarProtectAction } from '../constants/CarProtectAction';
import type { CarProtectActionType } from '../actions/CarProtectActions';
import { CarProtectProdcut } from '../../types/CarProtectProdcut';

interface CarProtectStateType {
  all: Record<string, CarProtectProdcut[]>;
  current: CarProtectProdcut | null;
}

const initialState: CarProtectStateType = {
  all: {},
  current: null,
};

const mapProducts = (
  products: CarProtectProdcut[]
): Record<string, CarProtectProdcut[]> => {
  return products.reduce((acc, product) => {
    if (!acc[product.brand]) {
      acc[product.brand] = [];
    }
    acc[product.brand].push(product);
    return acc;
  }, {} as Record<string, CarProtectProdcut[]>);
};

const carProtectReducer = (
  state: CarProtectStateType = initialState,
  action: CarProtectActionType
): CarProtectStateType => {
  switch (action.type) {
    case CarProtectAction.SET:
      return {
        ...state,
        all: mapProducts(action.products),
      };
    case CarProtectAction.SET_CURRENT:
      return {
        ...state,
        current: action.product,
      };
    case CarProtectAction.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default carProtectReducer;

export type CarProtectState = CarProtectStateType;
