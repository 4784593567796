import React from 'react'
import type { ReactNode } from 'react'

type TextHeaderProps = {
  children: string,
  className?: string,
  subtitle?: string,
  type?: string,
  color?: string,
}

const TextHeader = ({
  children,
  className,
  subtitle,
  type,
  color,
}: TextHeaderProps): ReactNode => {
  const renderHeading = (): ReactNode => {
    switch (type) {
      case 'h1':
        return (
          <h1 style={{ marginBottom: 4, color }}>
            <span className='c-section-heading__title-text'>{children}</span>
          </h1>
        )
      case 'h2':
        return (
          <h2 style={{ marginBottom: 4, color }}>
            <span className='c-section-heading__title-text'>{children}</span>
          </h2>
        )
      case 'h3':
        return (
          <h3 style={{ marginBottom: 4, color }}>
            <span className='c-section-heading__title-text'>{children}</span>
          </h3>
        )
      default:
        return (
          <h4 style={{ marginBottom: 4, color }}>
            <span className='c-section-heading__title-text'>{children}</span>
          </h4>
        )
    }
  }
  return (
    <header className={`${className ? className : ''} c-section-heading`}>
      {renderHeading()}
      {subtitle && <p className='c_section-heading__subtitle'>{subtitle}</p>}
    </header>
  )
}

export default TextHeader
