import React, { memo } from 'react'
import type { ReactNode } from 'react'

import { StyleSheet, css } from 'aphrodite/no-important'
import classNames from 'classnames'

import { Text, TextHeader } from '../common/'
import StyleGuide from '../../styles/StyleGuide'

type BeneftisPresenterProps = {
  beneftis: Array<Object>,
  darkBackground?: boolean,
  isAlt?: boolean,
}

const BenefitsPresenter = ({
  benefits = [],
  darkBackground = false,
  isAlt = false,
}: BeneftisPresenterProps): ReactNode => {
  const renderBenefits = (): ReactNode =>
    benefits.map((item: Object, idx: number): ReactNode => (
      <div className={css(styles.item)} key={idx}>
        <i className={css(styles.icon, styles[item.icon])} />
        <Text>{isAlt && item.alt ? item.alt : item.message}</Text>
      </div>
    ))

  return (
    <div
      className={classNames({
        [css(styles.dark)]: darkBackground,
        [css(styles.container)]: true,
      })}>
      <div className={'o-page-wrap  o-page-wrap--xsmall'}>
        <div className={css(styles.header)}>
          <TextHeader type={'h2'}>Korzyści dla Klienta:</TextHeader>
        </div>
        <div className={css(styles.content)}>{renderBenefits()}</div>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 56,
    paddingBottom: 80,
  },
  content: {
    columnCount: 2,
    columnGap: 30,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 30,
  },
  dark: {
    backgroundColor: StyleGuide.Colors.gray,
  },
  header: {
    paddingBottom: 70,
    textAlign: 'center',
  },
  icon: {
    paddingRight: 20,
    ':before': {
      fontFamily: 'bronson-bluelabel !important',
      fontWeight: 'normal',
      fontSize: 54,
      fontStyle: 'normal',
      fontVariant: 'normal',
      textTransform: 'none',
      display: 'inline-block',
      lineHeight: 1,
      content: "'\\e920'",
      color: StyleGuide.Colors.primary,
    },
  },
  iconControl: {
    ':before': {
      content: "'\\E961'",
    },
  },
  iconGuarantee: {
    ':before': {
      content: "'\\E937'",
    },
  },
  iconValue: {
    ':before': {
      content: "'\\E920'",
    },
  },
  iconFix: {
    ':before': {
      content: "'\\E9BF'",
    },
  },
  iconFlex: {
    ':before': {
      content: "'\\E915'",
    },
  },
  iconNoCash: {
    ':before': {
      content: "'\\E986'",
    },
  },
})

export default memo(BenefitsPresenter)
