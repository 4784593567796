import type { AuthActionType } from '../actions/AuthActions'
import { AuthAction } from '../constants/AuthAction'

type AuthStateType = {
  isLoggedIn: boolean,
  isLogging: boolean,
  error: string | null,
  data: Object | null,
}

const initialState: AuthStateType = {
  isLoggedIn: false,
  isLogging: true,
  error: null,
  data: null,
}

const authReducer = (
  state: AuthStateType = initialState,
  action: AuthActionType
): AuthStateType => {
  switch (action.type) {
    case AuthAction.LOGIN:
      return {
        ...state,
        isLogging: true,
        error: null,
      }
    case AuthAction.LOGIN_FAILED:
      return {
        isLoggedIn: false,
        isLogging: false,
        error: 'Unauthorized',
        data: null,
      }
    case AuthAction.LOGIN_SUCCESS:
    case AuthAction.RE_AUTH_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLogging: false,
        error: null,
        data: action.data,
      }
    case AuthAction.LOGOUT_SUCCESS:
    case AuthAction.RE_AUTH_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        isLogging: false,
        error: null,
        data: null,
      }
    default:
      return state
  }
}

export default authReducer
export type AuthState = AuthStateType
