import type { ReactNode } from 'react'
import React, { Component } from 'react'

import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import type { Action, Dispatch } from 'redux'
import { bindActionCreators } from 'redux'

import { login } from '../redux/actions/AuthActions'

type LoginViewProps = {
  login?: Function,
  history?: Object,
}

type LoginViewState = {
  user: string,
  password: string,
  isFailed: boolean,
}

class LoginView extends Component<LoginViewProps, LoginViewState> {
  state = {
    user: '',
    password: '',
    isFailed: false,
  }

  onKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onLogin()
    }
  }

  onLogin() {
    const { login } = this.props
    const { user, password } = this.state
    login(user, password)
  }

  render(): ReactNode {
    const { isFailed } = this.state

    return (
      <div className='c-hero-teaser  c-hero-teaser--auto'>
        <Helmet>
          <title>{'Pakiet przeglądów serwisowych VWFS'}</title>
        </Helmet>
        <img
          className='c-hero-teaser__image'
          srcSet='/assets/login/vw-top-1.jpg 480w, /assets/login/vw-top-1.jpg 720w, /assets/login/vw-top-1.jpg 960w, /assets/login/vw-top-1.jpg 1280w, /assets/login/vw-top-1.jpg 1920w'
          src='/assets/login/vw-top-1.jpg'
          sizes='100vw'
          alt='alternative text'
        />
        <div id='js-hero-teaser-content' className='c-hero-teaser__content'>
          <div className='c-hero-teaser__content-inner'>
            <div className='c-hero-teaser__box'>
              <fieldset className='o-fieldset'>
                <legend className='c-form-heading  '>
                  <strong className='c-form-heading__title'>
                    <i className='c-form-heading__icon  c-icon  c-icon--[semantic-login]' />
                    <span className='c-form-heading__title-text'>VWFS</span>
                  </strong>
                </legend>

                <div className='o-fieldset__row'>
                  <div className='c-form-field  '>
                    <label
                      htmlFor='kdwg0c6a'
                      className='c-form-field__label'
                      aria-describedby='kdwg0c6a-side-link'>
                      Użytkownik
                    </label>
                    <div className='c-form-field__box'>
                      <div className='c-input  '>
                        <input
                          onKeyDown={(e: any): void => this.onKeyPress(e)}
                          className='c-input__input  '
                          id='kdwg0c6a'
                          type='text'
                          placeholder='Użytkownik'
                          onChange={(e: any): void =>
                            this.setState({
                              isFailed: false,
                              user: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='o-fieldset__row'>
                  <div className='c-form-field  '>
                    <label
                      htmlFor='kdwhd5u3'
                      className='c-form-field__label'
                      aria-describedby='kdwhd5u3-side-link'>
                      Hasło
                    </label>
                    <div className='c-form-field__box'>
                      <div className='c-input  '>
                        <input
                          onKeyDown={(e: any): void => this.onKeyPress(e)}
                          className='c-input__input  '
                          id='kdwhd5u3'
                          type='password'
                          placeholder='Hasło'
                          onChange={(e: any): void =>
                            this.setState({
                              isFailed: false,
                              password: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div style={{ marginTop: '5px', height: '15px' }}>
                        {isFailed && (
                          <span>{'Błędna nazwa użytkownika lub hasło'}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className='o-button-container  o-button-container--split'>
                <button
                  className='c-btn  o-button-container__button'
                  onClick={(): void => this.onLogin()}>
                  <span className='c-btn__text'>Zaloguj</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: Object): Object => {
  return {
    // project: state.settings.project,
    // building: state.building.current,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): Object => {
  const actions = {
    login,
  }
  return bindActionCreators(actions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
