import React, { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';

import { StyleSheet, css } from 'aphrodite/no-important';

import {
  arePlansGrouped,
  selectOfferModel,
  selectOfferOption,
  selectOfferProduct,
  selectOfferPlans,
  selectOfferType,
  selectOfferYear,
  selectOfferEngine,
} from '../redux/selectors/offerSelectors';
import {
  selectConfigEngines,
  selectCurrentBrand,
  selectCurrentBrandInfo,
  selectMyBrand,
} from '../redux/selectors/configurationSelectors';

import { setOption } from '../redux/actions/OfferActions';

import { ProducConfirmationModal } from '../components/modals';
import { Text } from '../components/common';
import {
  ScopePresenter,
  BenefitsPresenter,
  DownloadSection,
  InfoSection,
  ImportantInformationsPresenter,
  PlansPresenter,
  ServiceActionsPresenter,
} from '../components/products';

import { printOffer } from '../utils/pdf/printOffer';

const groupPlans = (plans: Array<any>): Array<any> => {
  const options = plans.flatMap((plan: any): Array<any> => {
    return plan.options.map((option: any): any => ({
      ...option,
      symbol: plan.displaySymbol,
      tooltip: plan.tooltip,
    }));
  });
  return [
    {
      ...plans[0],
      options,
    },
  ];
};

type ProductPresentationViewProps = {
  arePlansGrouped: boolean;
  currentBrand: string;
  currentBrandInfo: any;
  currentPlans: Array<any>;
  currentType: any;
  history: any;
  model: any;
  myBrand: string;
  option: any;
  product: Array<any>;
  productionYear?: any;
  setOption: Function;
  engine?: number;
  allEngines?: Array<any>;
};

const ProductPresentationView = ({
  arePlansGrouped,
  currentBrand,
  currentBrandInfo,
  currentPlans,
  currentType,
  history,
  model,
  myBrand,
  option,
  product,
  productionYear,
  setOption,
  engine,
  allEngines,
}: ProductPresentationViewProps): ReactNode => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDoubleColor] = useState(true);
  const [plans] = useState(
    arePlansGrouped ? groupPlans(currentPlans) : currentPlans
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getTypeLabel = (): string => {
    if (productionYear) {
      return `${currentType.name}`;
    }
    return currentType.name;
  };

  const onSelectOption = (plan: any, item: any) => {
    setOption({
      ...item,
      planId: plan.id,
    });
    setIsModalOpen(true);
    window.dataLayer.push({
      event: 'GAEvent',
      eventCategory: 'Oferta szczegółowa',
      eventAction: 'View',
      eventLabel: '', // puste
      currentBrand: currentBrand,
      loggedBrand: myBrand,
      modelName: model.name,
      offerName: currentType.name,
      period: item.age,
      limit: item.distance,
      price: item.price,
      type: item.type,
      eventNonInteraction: false,
    });
  };

  const onConfirmProduct = () => {
    setIsModalOpen(false);
  };

  const onPrintOffer = (option: any = null) => {
    let printPlans;
    let printProduct: { [name: string]: unknown } = product as unknown as {
      [name: string]: unknown;
    };
    if (option === null) {
      if (engine) {
        printPlans = plans.map((item: any): any => {
          let options = item.options;
          options = options.filter((opt: any): any => opt.engineId === engine);
          return {
            ...item,
            options,
          };
        });
      } else {
        printPlans = plans;
      }
    } else {
      printPlans = plans.map((item: any): any => {
        let options = item.options;
        if (engine) {
          options = options.filter((opt: any): any => opt.engineId === engine);
        }
        if (item.id === option.planId) {
          return {
            ...item,
            options: options.map((opt: any): any => {
              if (
                opt.age === option.age &&
                opt.distance === option.distance &&
                opt.price === option.price
              ) {
                return {
                  ...opt,
                  selected: true,
                };
              }
              return opt;
            }),
          };
        }
        return {
          ...item,
          options,
        };
      });
    }

    if (engine) {
      printProduct = {
        ...printProduct,
        scopes: {
          ...printProduct.scopes,
          rows: printProduct.scopes.rows.filter((item: any) =>
            item.engines ? item.engines.includes(engine) : true
          ),
        },
      };
    } else {
      printPlans = plans;
    }
    console.log({
      currentBrand,
      product: printProduct,
      printPlans,
      model,
      option,
    });
    printOffer(
      currentBrand,
      printProduct,
      printPlans,
      model,
      option,
      getTypeLabel(),
      (engine && allEngines) ? allEngines[engine]?.label : null
    );
  };

  const renderModal = (): ReactNode => (
    <ProducConfirmationModal
      brandInfo={currentBrandInfo}
      onClose={(): void => setIsModalOpen(false)}
      onConfirm={onConfirmProduct}
      onPrint={(item: any) => {
        onPrintOffer(item);
        window.dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'Drukuj szczegóły',
          eventAction: 'Click',
          eventLabel: '', // puste
          currentBrand: currentBrand,
          loggedBrand: myBrand,
          modelName: model.name,
          offerName: currentType.name,
          period: item.age,
          limit: item.distance,
          price: item.price,
          eventNonInteraction: false,
        });
      }}
      plans={plans}
      option={option}
      model={model}
      product={product}
      productionYear={productionYear}
    />
  );

  const renderPlans = (): ReactNode => (
    <PlansPresenter
      isGrouped={arePlansGrouped}
      plans={plans}
      engineId={engine}
      engine={allEngines && engine ? allEngines[engine] : null}
      onSelect={onSelectOption}
      onPrint={() => {
        onPrintOffer();
        window.dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'Drukuj',
          eventAction: 'Click',
          eventLabel: '', // puste
          currentBrand: currentBrand,
          loggedBrand: myBrand,
          modelName: model.name,
          offerName: currentType.name,
          period: '', // może pozostać puste
          limit: '', // może pozostać puste
          price: '', // może pozostać puste
          eventNonInteraction: false,
        });
      }}
    />
  );

  const renderScopes = (): ReactNode => (
    <ScopePresenter
      darkBackground={isDoubleColor}
      engineId={engine}
      data={product.scopes}
    />
  );

  const renderInfo = (): ReactNode => (
    <InfoSection
      title={product.info.title}
      text={product.info.texts}
      darkBackground={!isDoubleColor}
    />
  );

  const renderServiceActions = (): ReactNode => (
    <ServiceActionsPresenter
      services={product.services}
      darkBackground={!isDoubleColor}
    />
  );

  const renderBenefits = (): ReactNode => (
    <BenefitsPresenter
      isAlt={!currentType.isNew}
      benefits={product.benefits}
      darkBackground={isDoubleColor}
    />
  );
  const renderDownloads = (): ReactNode => (
    <DownloadSection
      downloads={product.downloads}
      darkBackground={!isDoubleColor}
      model={model}
      onDownloaded={(item: any) => {
        window.dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'Dokumenty do pobrania',
          eventAction: 'Click',
          eventLabel: '', // pozostaje puste
          loggedBrand: myBrand,
          currentBrand: currentBrand,
          modelName: model.name,
          offerName: currentType.name,
          docTitle: item.title, // Wyświetlany tytuł dokumenty na stronie
          docPath: item.path.split('/')[3], // fizyczna nazwa pliku lub cały adres do pliku
          eventNonInteraction: false,
        });
      }}
    />
  );

  const renderInformations = (): ReactNode => (
    <ImportantInformationsPresenter
      brand={currentBrand}
      data={product.important}
      darkBackground={isDoubleColor}
    />
  );

  return (
    <div>
      <Helmet>
        <title>{`Oferta Pakietów Usług Serwisowych ${currentBrand.toUpperCase()}`}</title>
      </Helmet>
      <div className={css(styles.container)}>
        <Text>{getTypeLabel()}</Text>
      </div>
      {renderPlans()}
      {renderScopes()}
      {renderInfo()}
      {renderBenefits()}
      {renderServiceActions()}
      {renderInformations()}
      {renderDownloads()}
      {isModalOpen && renderModal()}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  arePlansGrouped: arePlansGrouped,
  currentBrand: selectCurrentBrand,
  currentBrandInfo: selectCurrentBrandInfo,
  currentPlans: selectOfferPlans,
  currentType: selectOfferType,
  model: selectOfferModel,
  myBrand: selectMyBrand,
  option: selectOfferOption,
  product: selectOfferProduct,
  productionYear: selectOfferYear,
  engine: selectOfferEngine,
  allEngines: selectConfigEngines,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): any => {
  const actions = {
    setOption,
  };
  return bindActionCreators(actions, dispatch);
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPresentationView);
