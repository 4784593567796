import Api from './Api'

class AuthApi extends Api {
  static resource: string = 'login'

  static login(user: string, password: string): any {
    const data = {
      user,
      password,
    }
    return super
      .post(`${AuthApi.resource}`, data)
      .then((response: Object): Object => response.json())
      .then((data: Object): any => {
        return data
      })
  }

  static reAuth(): any {
    return super.get('user').then((data: Object): any => {
      return data
    })
  }
}

export default AuthApi
