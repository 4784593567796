const AuthAction = {
  LOGIN: 'AUTH_LOGIN',
  LOGIN_FAILED: 'AUTH_LOGIN_FAILED',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
  RE_AUTH_FAILED: 'AUTH_RE_AUTH_FAILED',
  RE_AUTH_SUCCESS: 'AUTH_RE_AUTH_SUCCESS',
}

export { AuthAction }
