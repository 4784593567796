import { css } from 'aphrodite';
import React, { ReactNode } from 'react';
import {
  CarProtectInfo,
  CarProtectProductPackage,
} from '../../../types/CarProtectProdcut';
import { Text, TextHeader } from '../../common';
import styles from './CarProtectInformations.styles';
import classNames from 'classnames';

interface CarProtectInformationsProps {
  info: CarProtectInfo;
}

const CarProtectInformations = ({ info }: CarProtectInformationsProps) => {
  const { title, packages, labels } = info;

  const headers = ['', ...packages.map((item) => item.title)];

  const renderHeader = (text: string, idx: number): ReactNode => (
    <th
      key={idx}
      className={classNames({
        [css(styles.cell)]: true,
        [css(styles.cellHeader)]: true,
        [css(styles.wideColumn)]: idx === 0,
      })}>
      {text}
    </th>
  );

  const renderRow = (idx: number): ReactNode => {
    return (
      <tr key={idx}>
        <td key={idx} className={css(styles.cell, styles.cellLeft)}>
          {labels[idx + 1]}
        </td>
        {packages.map((item: CarProtectProductPackage, index: number) => {
          return (
            <td key={index} className={css(styles.cell)}>
              <Text>{item.info[idx]}</Text>
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <div className={css(styles.container)}>
      <div className={'o-page-wrap  o-page-wrap--xsmall'}>
        <TextHeader type={'h3'}>{title}</TextHeader>
      </div>
      <table className={css(styles.table)} cellSpacing='0'>
        <thead>
          <tr>
            {headers.map(
              (item: string, idx: number): ReactNode => renderHeader(item, idx)
            )}
          </tr>
        </thead>
        <tbody>
          {labels
            .slice(0, labels.length - 2)
            .map((label: string, idx: number): ReactNode => renderRow(idx))}
        </tbody>
      </table>
    </div>
  );
};

export default CarProtectInformations;
