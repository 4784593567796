/* cSpell:disable */
import React, { Component, Fragment } from 'react'
import type { ReactNode } from 'react'

type NotSupportedProps = {}

class NotSupported extends Component<NotSupportedProps> {
  render(): ReactNode {
    return (
      <Fragment>
        <header className='c-header__wrapper'>
          <div className='c-header  '>
            <div className='c-header__content-wrapper'>
              <div className='c-header__logo'>
                <a className='c-logo  c-logo--has-image' href='/' title=''>
                  <picture>
                    <source
                      srcSet='https://cdn.bronson.vwfs.tools/bluelabel/v/7.9.0/img/logo.svg'
                      media='(max-width: 720px)'
                    />
                    <img
                      src='https://cdn.bronson.vwfs.tools/bluelabel/v/7.9.0/img/logo.svg'
                      alt='logo'
                      className='c-logo__image'
                    />
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </header>
        <main className='o-main  '>
          <section className='o-content-section  '>
            <div className='o-page-wrap  o-page-wrap--xsmall'>
              <div>
                <header className='c-section-heading '>
                  <h3 className='c-section-heading__title'>
                    <span className='c-section-heading__title-text'>
                      {'Nieobsługiwana przeglądarka'}
                    </span>
                  </h3>
                  {
                    'Przeglądarka Internet Explorer nie jest obsługiwana. Prosimy o skorzystania z nowszych przeglądarek takich jak Chrome, Mozilla Firefox, Microsoft Edge.'
                  }
                </header>
              </div>
            </div>
          </section>
        </main>
      </Fragment>
    )
  }
}

export default NotSupported
