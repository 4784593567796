import React, { memo } from 'react'
import type { ReactNode } from 'react'

import { StyleSheet, css } from 'aphrodite/no-important'

import SinglePlanPresentation from './SinglePlanPresentation'

import { Button } from '../common'

type PlansPresenterType = {
  onSelect: Function,
  isGrouped: boolean,
  engineId?: number,
  plans: Array<Object>,
  onPrint: Function,
  engine: { id: number, label: string },
}

const PlansPresenter = ({
  onSelect,
  isGrouped,
  engineId,
  plans = [],
  onPrint,
  engine,
}: PlansPresenterType): ReactNode => {
  const renderPrintButton = (): ReactNode => (
    <Button onClick={onPrint}>{'Drukuj ofertę'}</Button>
  )
  return (
    <div
      className={`o-page-wrap  o-page-wrap--xsmall ${css(styles.container)}`}>
      {plans.map((item: Object): ReactNode => (
        <SinglePlanPresentation
          engine={engine}
          isGrouped={isGrouped}
          key={item.id}
          onSelect={onSelect}
          engineId={engineId}
          plan={item}
        />
      ))}
      <div className={`${css(styles.buttonContainer)}`}>
        {renderPrintButton()}
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 70,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default memo(PlansPresenter)
