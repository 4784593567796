import { StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 100,
    width: '100%',
  },
  brandContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 30,
    paddingBottom: 120,
  },
  productsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: 30,
    alignItems: 'center',
    paddingTop: 30,
    width: '100%',
  },
  logo: {
    maxHeight: 200,
    width: 'auto',
    paddingTop: 30,
    paddingBottom: 50,
  },
});

export default styles;