import { createSelector } from 'reselect';
import { selectCurrentBrand } from './configurationSelectors';
import { CarProtectProdcut } from '../../types/CarProtectProdcut';

const selectCarProtectProducts = (
  state: any
): Record<string, CarProtectProdcut[]> => state.carProtect.all;

const selectCurrentCarProtectProduct = (state: any): CarProtectProdcut =>
  state.carProtect.current;

const makeCurrentBrandCarProtectProductsSelector = createSelector(
  [selectCarProtectProducts, selectCurrentBrand],
  (
    products: Record<string, CarProtectProdcut[]>,
    brand: string
  ): CarProtectProdcut[] => {
    return products[brand] || [];
  }
);

export {
  selectCurrentCarProtectProduct,
  makeCurrentBrandCarProtectProductsSelector,
};
