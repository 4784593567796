import React, { memo } from 'react'
import type { ReactNode } from 'react'

import { StyleSheet, css } from 'aphrodite/no-important'
import classNames from 'classnames'

import { ModalLarge, TextHeader } from '../common'
import { getLogo } from '../../utils/getLogo'

type BrandChooserModalProps = {
  brands: Array<Object>,
  onChoose: Function,
  onClose: Function,
}

const BrandChooserModal = ({
  brands,
  onChoose,
  onClose,
}: BrandChooserModalProps): ReactNode => {
  const renderBrandButton = (brand: Object): ReactNode => (
    <div key={brand.key}>
      <div
        className={classNames({
          [css(styles.brand)]: true,
          [css(styles.brandBig)]: brand.key === 'vwd',
        })}
        onClick={(): void => onChoose(brand.key)}>
        <img
          className={classNames({
            [css(styles.logo)]: true,
            [css(styles.logoBig)]: brand.key === 'vwd',
          })}
          src={getLogo(brand.key)}
          alt=''
        />
      </div>
    </div>
  )

  return (
    <ModalLarge onClose={onClose}>
      <TextHeader type={'h2'}>{'Wybierz markę:'}</TextHeader>
      <div className={css(styles.contentContainer)}>
        {brands && brands.map((item: Object): ReactNode => renderBrandButton(item))}
      </div>
    </ModalLarge>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 80,
  },
  brand: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 40px',
    cursor: 'pointer',
  },
  brandBig: {
    padding: '15px 40px',
  },
  logo: {
    maxHeight: 70,
    maxWidth: 100,
  },
  logoBig: {
    maxHeight: 100,
  },
})

export default memo(BrandChooserModal)
