import React, { memo, useState } from 'react';
import type { ReactNode } from 'react';

import { StyleSheet, css } from 'aphrodite/no-important';
import classNames from 'classnames';

import { TextHeader, Tooltip } from '../common';
import StyleGuide from '../../styles/StyleGuide';
import { formatPrice } from '../../utils/formatters/formatPrice';

type SinglePlanItemProps = {
  type?: string;
  item: any;
  onSelect: Function;
};

const getPriceString = (price: string, base: string): string => {
  if (isNaN(price)) {
    if (price === '__PRICE__' && base !== '__BASE__') {
      return isNaN(base) ? base : `${formatPrice(base)}`;
    }
    return price;
  }
  return `${formatPrice(price)}`;
};

const SinglePlanItem = ({ type, item, onSelect }: SinglePlanItemProps): ReactNode => {
  const [show, setShow] = useState(false);

  const renderActionButton = (item: Object): ReactNode => (
    <div
      className={css(styles.actionButton)}
      onClick={(): void => onSelect(item)}>
      {'>'}
    </div>
  );

  return (
    <tr
      onMouseEnter={(): void => setShow(true)}
      onMouseLeave={(): void => setShow(false)}
      key={item.id}>
      {item.symbol && (
        <td className={css(styles.cellContainer)}>
          <div
            className={classNames({
              [css(styles.simpleContainer)]: true,
              [css(styles.noPromoContainer)]: !item.promoted,
              [css(styles.promoCell)]: item.promoted,
            })}>
            {item.promoted && (
              <div className={css(styles.promoText)}>
                <span>{'PROMO'}</span>
              </div>
            )}
            <TextHeader
              className={classNames({
                [css(styles.cellText)]: true,
                [css(styles.promoCellText)]: item.promoted,
              })}>
              {item.symbol}
            </TextHeader>
            <div className={css(styles.tooltipContainer)}>
              <Tooltip text={item.tooltip}>
                <i className={css(styles.infoIcon)} />
              </Tooltip>
            </div>
          </div>
        </td>
      )}
      <td className={css(styles.cellContainer)}>
        <div
          className={classNames({
            [css(styles.simpleContainer)]: true,
            [css(styles.noPromoContainer)]: !item.promoted,
            [css(styles.promoCell)]: item.promoted,
          })}>
          {item.promoted && !item.symbol && (
            <div className={css(styles.promoText)}>
              <span>{'PROMO'}</span>
            </div>
          )}
          <TextHeader
            className={classNames({
              [css(styles.cellText)]: true,
              [css(styles.promoCellText)]: item.promoted,
            })}>{`${item.age} mies.`}</TextHeader>
        </div>
      </td>
      <td className={css(styles.cellContainer)}>
        <div
          className={classNames({
            [css(styles.simpleContainer)]: true,
            [css(styles.promoCell)]: item.promoted,
          })}>
          <TextHeader
            className={`${css(styles.cellText)} ${css(styles.cellTextLeft)}`} >
              {type === 'engine' ? item.type : `${item.distance} tys. km`}</TextHeader>
        </div>
      </td>
      {item.nadin !== undefined && (
        <td className={css(styles.cellContainer)}>
          <div
            className={classNames({
              [css(styles.simpleContainer)]: true,
              [css(styles.promoCell)]: item.promoted,
            })}>
            <TextHeader className={css(styles.cellText)}>
              {item.nadin}
            </TextHeader>
          </div>
        </td>
      )}
      {item.price !== undefined && (
        <td className={css(styles.cellContainer)}>
          <div
            className={classNames({
              [css(styles.simpleContainer)]: true,
              [css(styles.simpleContainerVertical)]: item.base !== '__BASE__',
              [css(styles.promoCell)]: item.promoted,
              [css(styles.promoCelVertical)]:
                item.promoted && item.base !== '__BASE__',
            })}>
            {item.base !== '__BASE__' &&
              item.base !== 0 &&
              item.price !== '__PRICE__' && (
                <TextHeader
                  color={'#BBB7B6'}
                  className={classNames({
                    [css(styles.cellText)]: true,
                    [css(styles.cellTextCrossedOut)]: true,
                  })}>
                  {isNaN(item.base)
                    ? item.base
                    : `${formatPrice(item.base)} zł`}
                </TextHeader>
              )}
            <TextHeader className={css(styles.cellText)}>
              {getPriceString(item.price, item.base)}
            </TextHeader>
          </div>
        </td>
      )}
      {item.installment !== undefined &&
        item.installment !== '__INSTALLMENT__' && (
          <td className={css(styles.cellContainer)}>
            <div
              className={classNames({
                [css(styles.simpleContainer)]: true,
                [css(styles.promoCell)]: item.promoted,
              })}>
              <TextHeader className={css(styles.cellText)}>
                {isNaN(item.installment) &&
                item.installment[item.installment.length - 1] !== '*'
                  ? item.installment
                  : `${formatPrice(item.installment)}`}
              </TextHeader>
            </div>
          </td>
        )}
      <td className={css(styles.cellContainer)}>
        <div
          className={classNames({
            [css(styles.simpleContainer)]: true,
            [css(styles.actionContainer)]: true,
            [css(styles.promoCell)]: item.promoted,
            [css(styles.promoCellLast)]: item.promoted,
          })}>
          {show && renderActionButton(item)}
        </div>
      </td>
    </tr>
  );
};

const styles = StyleSheet.create({
  simpleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 69,
    justifyContent: 'flex-end',
    alignItems: 'baseline',
  },
  simpleContainerVertical: {
    flexDirection: 'column',
    height: 56,
    paddingBottom: 3,
  },
  tooltipContainer: {
    paddingRight: 20,
  },
  infoIcon: {
    ':before': {
      fontFamily: 'bronson-bluelabel !important',
      fontWeight: 'normal',
      fontSize: 18,
      fontStyle: 'normal',
      fontVariant: 'normal',
      textTransform: 'none',
      display: 'inline-block',
      lineHeight: 1,
      content: "'\\E9EC'",
      color: StyleGuide.Colors.primary,
    },
  },
  noPromoContainer: {
    // paddingLeft: 26,
    paddingLeft: 10,
  },
  cellText: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    paddingLeft: 10,
    marginBottom: -10,
  },
  cellTextLeft: {
    justifyContent: 'flex-start',
    marginLeft: 10,
  },
  cellTextCrossedOut: {
    textDecoration: 'line-through',
    color: '#BBB7B6',
  },
  promoCellText: {
    marginLeft: '-42px',
  },
  promoCelVertical: {
    height: 69,
    paddingBottom: 10,
  },
  promoText: {
    transform: 'rotate(-90deg) translate(-100%, 0%)',
    transformOrigin: '-20px -20px',
    '-webkit-transform': 'rotate(-90deg) translate(-100%, 0%)',
    '-moz-transform': 'rotate(-90deg) translate(-100%, 0%)',
    '-ms-transform': 'rotate(-90deg) translate(-100%, 0%)',
    '-o-transform': 'rotate(-90deg) translate(-100%, 0%)',
    backgroundColor: StyleGuide.Colors.primary,
    color: 'white',
    fontWeight: 700,
    fontSize: 16,
    padding: '4px 8px',
    width: 68,
    height: 26,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  promoCell: {
    borderBottom: `1px solid ${StyleGuide.Colors.primary}`,
    borderTop: `1px solid ${StyleGuide.Colors.primary}`,
  },
  promoCellLast: {
    borderRight: `1px solid ${StyleGuide.Colors.primary}`,
  },
  cellContainer: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButton: {
    border: `1px solid ${StyleGuide.Colors.primary} !important`,
    backgroundColor: StyleGuide.Colors.secondary,
    width: 30,
    height: 30,
    fontSize: 28,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

export default memo(SinglePlanItem);
