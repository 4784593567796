import React from 'react';
import { CarProtectContact } from '../../../types/CarProtectProdcut';
import { Text, TextHeader } from '../../common';
import { css } from 'aphrodite';
import styles from './CarProtectContact.styles';

interface CarProtectProdcutProps {
  contact: CarProtectContact;
}

const CarProtectProdcut = ({ contact }: CarProtectProdcutProps) => {
  const { title, name, description, phone, email, url } = contact;
  return (
    <div className={css(styles.container)}>
      <div className={'o-page-wrap  o-page-wrap--xsmall'}>
        <TextHeader type={'h3'}>{title}</TextHeader>
      </div>
      <div>
        <Text>{description}</Text>
        <Text>{name}</Text>
        <Text>{`telefon: ${phone}`}</Text>
        <Text>
          {`email: `}
          <a href={`mailto:${email}`}>{email}</a>
        </Text>

        <Text>
          {`adres: `}
          <a href={url}>{url}</a>
        </Text>
      </div>
    </div>
  );
};

export default CarProtectProdcut;
