const selectOfferModel = (state: any): any | null => state.offer.model;

const selectOfferOption = (state: any): any | null => state.offer.option;

const selectOfferProduct = (state: any): any | null =>
  state.offer.product;

const selectOfferPlans = (state: any): Array<any> => {
  if (state.offer.product && state.offer.model) {
    const productForModel = state.offer.model.products.find(
      (item: any): any => item.id === state.offer.product.id
    );
    if (productForModel) {
      return productForModel.plans.map(
        (item: any): any => ({
          ...item,
          ...state.config.plans[item.id],
          options: item.options.map(
            (opt: any): any => ({
              ...opt,
              ...state.config.options[opt.id],
            })
          ),
        })
      );
    }
  }
  return [];
};

const selectOfferType = (state: any): any | null => state.offer.type;

const selectOfferEngine = (state: any): any | null => state.offer.engine;

const selectOfferYear = (state: any): any | null => state.offer.year;

const arePlansGrouped = (state: any): boolean => {
  if (state.offer.model && state.offer.product) {
    const currentProductId: number = state.offer.product.id;
    const product = state.offer.model.products.find(
      (item: any): boolean => item.id === currentProductId
    );
    return product && !!product.grouped;
  }
  return false;
};

export {
  arePlansGrouped,
  selectOfferModel,
  selectOfferOption,
  selectOfferProduct,
  selectOfferPlans,
  selectOfferType,
  selectOfferYear,
  selectOfferEngine,
};
