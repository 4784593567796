import React from 'react'
import ReactDOM from 'react-dom'

import Application from './Application'

import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, AnyAction } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer, { RootState } from './redux/reducers'

const middleware =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(thunk))
    : applyMiddleware(thunk)
const store = createStore<RootState, AnyAction, any, any>(rootReducer, middleware)

const container = document.getElementById('root')
if (container) {
  ReactDOM.render(
    <Provider store={store}>
      <Application />
    </Provider>,
    container
  )
}
