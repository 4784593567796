import React from 'react'
import type { ReactNode } from 'react'

type NavItemProps = {
  children: ReactNode,
  onClick?: Function,
}
const NavItem = ({ children, onClick }: NavItemProps): ReactNode => (
  <li className={'c-site-nav__item '}>
    <button
      className='c-site-nav__link c-site-nav__link-label'
      onClick={() => onClick()}>
      {children}
    </button>
  </li>
)

export default NavItem
