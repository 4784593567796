/* eslint-disable no-irregular-whitespace */
import React, { memo } from 'react'
import type { ReactNode } from 'react'

import { StyleSheet, css } from 'aphrodite/no-important'
import classNames from 'classnames'

import { TextHeader } from '../common'
import StyleGuide from '../../styles/StyleGuide'

import CheckboxCheck from '../../icons/checkbox-checked.svg'
import CheckboxNoCheck from '../../icons/checkbox.svg'

type ScopePresenterProps = {
  darkBackground?: boolean,
  data: Object,
  engineId: number,
}

const ScopePresenter = ({
  darkBackground = false,
  data,
  engineId,
}: ScopePresenterProps): ReactNode => {
  const renderCheckbox = (check: boolean): ReactNode => (
    <img
      className={css(styles.checkbox)}
      src={check ? CheckboxCheck : CheckboxNoCheck}
      alt=''
    />
  )

  const renderHeader = (text: string, idx: number): ReactNode => (
    <th
      key={idx}
      className={classNames({
        [css(styles.cell)]: true,
        [css(styles.cellHeader)]: true,
        [css(styles.wideColumn)]: idx === 0,
      })}>
      {text}
    </th>
  )

  const renderRow = (data: Object, idx: number): ReactNode => {
    if (engineId && data.engines && !data.engines.includes(engineId)) {
      return null;
    }
    return (
      <tr key={idx}>
        {data.entries.map((item: Object, idx: number): ReactNode => {
          if (item.type === 'text') {
            return (
              <td key={idx} className={css(styles.cell, styles.cellLeft)}>
                {item.caption && <strong>{`${item.caption} `}</strong>}
                {item.value}
              </td>
            )
          } else if (item.type === 'bool') {
            return (
              <td key={idx} className={css(styles.cell)}>
                {renderCheckbox(item.value)}
              </td>
            )
          }
          return null
        })}
      </tr>
    )
  }

  return (
    <div
      className={classNames({
        [css(styles.dark)]: darkBackground,
        [css(styles.container)]: true,
      })}>
      <div className={'o-page-wrap  o-page-wrap--xsmall'}>
        <div className={css(styles.header)}>
          <TextHeader type={'h2'}>Zakresy</TextHeader>
        </div>
        <table className={css(styles.table)} cellSpacing='0'>
          <thead>
            <tr>
              {data.headers.map((item: string, idx: number): ReactNode =>
                renderHeader(item, idx)
              )}
            </tr>
          </thead>
          <tbody>
            {data.rows.map((item: Object, idx: number): ReactNode =>
              renderRow(item, idx)
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 32,
    paddingBottom: 140,
  },
  table: {
    width: '100%',
  },
  header: {
    paddingBottom: 16,
    textAlign: 'center',
  },
  dark: {
    backgroundColor: StyleGuide.Colors.gray,
  },
  cell: {
    borderBottom: '1px solid #adadad',
    cellSpacing: 'none',
    textAlign: 'center',
    verticalAlign: 'center',
    height: 80,
    padding: '8px 8px',
    whiteSpace: 'break-spaces',
  },
  cellLeft: {
    textAlign: 'left',
  },
  cellHeader: {
    height: '40px',
    fontWeight: 400,
    fontSize: 16,
    paddingBottom: 5,
  },
  wideColumn: {
    width: '70%',
    textAlign: 'left',
    verticalAlign: 'bottom',
  },
  checkbox: {
    width: 19,
    height: 19,
  },
})

export default memo(ScopePresenter)
