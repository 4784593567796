import React, { Component, Fragment } from 'react'
import type { ReactNode } from 'react'

import classNames from 'classnames'
import { StyleSheet, css } from 'aphrodite/no-important'

import TopMenu from '../menu/TopMenu'

interface BrandInfo {
  displayName: string
  image: string
}

interface Model {
  name: string
  image: string
}

type HeaderProps = {
  brandInfo: BrandInfo,
  history: History & { push(path: string): void },
  location: Location,
  logout: Function,
  model?: Model,
  user: string,
}

class Header extends Component<HeaderProps> {
  getHeaderTitle = (path: string): string => {
    if (path === '/configuration') {
      return 'Pakiet usług serwisowych'
    }
    if (path === '/product') {
      return `Pakiet usług serwisowych`
    }
    if (path === '/car_protect') {
      return `Ochrona pogwarancyjna`
    }
    if (path === '/car_protect_product') {
      return `Ochrona pogwarancyjna`
    }
    return ''
  }

  getHeaderSubtitle = (path: string): string => {
    const { brandInfo, model } = this.props
    if (path === '/product' && model) {
      return `${brandInfo.displayName} ${model.name}`.toUpperCase()
    }
    return brandInfo ? brandInfo.displayName.toUpperCase() : ''
  }

  getModelImagePath = (): string => {
    const { model } = this.props
    return `/assets/${model?.image || ''}`
  }

  getBrandImagePath = (): string => {
    const { brandInfo } = this.props
    // console.log(brandInfo)
    return brandInfo && `/assets/${brandInfo.image}`
  }

  renderTeaserImage = (path: string): ReactNode => {
    const { model } = this.props
    if (path === '/product' && model) {
      return (
        <div className={css(styles.teaserBackground)}>
          <div className={'o-page-wrap'} style={{ paddingTop: 95 }}>
            <img src={this.getModelImagePath()} alt='' />
          </div>
        </div>
      )
    }
    return (
      <img
        className={`c-hero-teaser__image ${css(styles.teaserImage)}`}
        srcSet={`${this.getBrandImagePath()} 480w, ${this.getBrandImagePath()} 720w, ${this.getBrandImagePath()} 960w, ${this.getBrandImagePath()} 1280w, ${this.getBrandImagePath()} 1920w`}
        src={`${this.getBrandImagePath()}`}
        sizes='100vw'
        alt='alternative text'
      />
    )
  }

  render(): ReactNode {
    const { history, location, logout, user } = this.props
    const innerHeadingStyle = css(styles.innerHeadingContainer)
    const headingStyle = css(styles.headingContainer)

    return (
      <Fragment>
        <header className='c-header__wrapper'>
          <div className='c-header  '>
            <div className='c-header__content-wrapper'>
              <div className='c-header__logo'>
                <a className='c-logo  c-logo--has-image' href='/' title=''>
                  <picture>
                    <source
                      srcSet='https://cdn.bronson.vwfs.tools/bluelabel/v/7.9.0/img/logo.svg'
                      media='(max-width: 720px)'
                    />
                    <img
                      src='https://cdn.bronson.vwfs.tools/bluelabel/v/7.9.0/img/logo.svg'
                      alt='logo'
                      className='c-logo__image'
                    />
                  </picture>
                </a>
              </div>
              <TopMenu
                location={location}
                logout={logout}
                history={history}
                user={user}
              />
            </div>
          </div>
        </header>
        <div className={`c-hero-teaser ${css(styles.shadow)}`}>
          <div
            id='js-hero-teaser-content'
            className={`c-hero-teaser__content ${css(
              styles.contentContainer
            )}`}>
            <div
              className={classNames({
                'c-hero-teaser__content-inner': true,
                [innerHeadingStyle]: true,
              })}>
              <header
                className={classNames({
                  'c-hero-teaser__heading': true,
                  [headingStyle]: true,
                })}>
                {
                  <div className='c-hero-teaser__subtitle'>
                    <p>{this.getHeaderTitle(location.pathname)}</p>
                  </div>
                }
                {
                  <div className='c-hero-teaser__title' style={{ display: 'flex' }}>

                    <h1>
                      <span className='c-hero-teaser__title-row'>
                        {this.getHeaderSubtitle(location.pathname)}
                      </span>
                    </h1>
                  </div>
                }
              </header>
            </div>
          </div>
          {this.renderTeaserImage(location.pathname)}
          <div className={css(styles.teaserBottomBar)} />
        </div>
      </Fragment>
    )
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    paddingBottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  innerHeadingContainer: {
    width: '100%',
  },
  headingContainer: {
    alignItems: 'flex-end',
  },
  teaserImage: {
    height: 400,
  },
  teaserBackground: {
    width: '100%',
    height: 400,
    backgroundImage:
      'linear-gradient(to bottom, rgba(199, 199, 199, 0.5), rgba(255, 255, 255, 0.5))',
    '& img': {
      padddingTop: 112,
    },
  },
  teaserBottomBar: {
    height: 56,
    width: '100%',
    backgroundColor: 'white',
  },
  shadow: {
    boxShadow: '40px 0 40px 0 rgba(0, 0, 0, 0.3)',
  },
})

export default Header
