import React, { Component } from 'react'
import type { ReactNode } from 'react'

type CheckboxProps = {
  checked: boolean,
  disabled?: boolean,
  label: string,
  onChange: Function,
}

class Checkbox extends Component<CheckboxProps> {
  render(): ReactNode {
    const { checked, disabled, label, onChange } = this.props
    return (
      <div className='o-fieldset__row'>
        <div className='o-layout'>
          <div className='o-layout__item  u-1/1@xs'>
            <div className='c-form-field  '>
              <div className='c-form-field__box'>
                <label className='c-checkbox'>
                  <input
                    className='c-checkbox__input  '
                    type='checkbox'
                    name='checkbox'
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                  />
                  <span className='c-checkbox__label'>{label}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Checkbox
