import React, { memo, useState } from 'react'
import type { ReactNode } from 'react'

import classNames from 'classnames'
import { StyleSheet, css } from 'aphrodite/no-important'

import { TextHeader } from '../common'
import StyleGuide from '../../styles/StyleGuide'

type ServiceActionsPresenterProps = {
  darkBackground?: boolean,
  services: Array<Object>,
}
const ServiceActionsPresenter = ({
  darkBackground = false,
  services = {},
}: ServiceActionsPresenterProps): ReactNode => {
  const [current, setCurrent] = useState(services.steps[0])

  const renderItem = (step: Object): ReactNode => (
    <li
      key={step.id}
      className={classNames({
        'c-stepper-nav__item ': true,
        'is-active': step.id !== current.id,
        'is-current': step.id === current.id,
      })}>
      <div
        className='c-stepper-nav__link'
        onClick={(): void => setCurrent(step)}>
        <div className='c-stepper-nav__box' />
      </div>
    </li>
  )

  return (
    <div
      className={classNames({
        [css(styles.dark)]: darkBackground,
        [css(styles.container)]: true,
      })}>
      <div
        className={`${css(
          styles.innerContainer
        )} o-page-wrap  o-page-wrap--xsmall`}>
        <TextHeader className={css(styles.header)} type={'h2'}>
          {'Wykonanie czynności serwisowych przez Klientów'}
        </TextHeader>
        <div>
          <nav className='c-stepper-nav  '>
            <ol className='c-stepper-nav__list'>
              {services.steps.map((item: Object): ReactNode => renderItem(item))}
            </ol>
          </nav>
        </div>
        <div className={css(styles.content)}>
          <TextHeader type={'h4'}>{current.text}</TextHeader>
        </div>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 32,
    paddingBottom: 110,
  },
  innerContainer: {
    borderBottom: `1px solid ${StyleGuide.Colors.lightGray}`,
    paddingBottom: 32,
  },
  dark: {
    backgroundColor: StyleGuide.Colors.gray,
  },
  header: {
    paddingBottom: 32,
    textAlign: 'center',
  },
  content: {
    padding: '60px 60px 0 60px',
    textAlign: 'center',
    minHeight: 160,
  },
})

export default memo(ServiceActionsPresenter)
