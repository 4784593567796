import React, { memo } from 'react'
import type { ReactNode } from 'react'

import { StyleSheet, css } from 'aphrodite/no-important'
import classNames from 'classnames'

import { DownloadLink, TextHeader } from '../common'
import StyleGuide from '../../styles/StyleGuide'

type DownloadSectionProps = {
  darkBackground?: boolean,
  downloads: Array<Object>,
  model: Object,
  onDownloaded: Function,
}

const DownloadSection = ({
  darkBackground = false,
  downloads = [],
  model,
  onDownloaded,
}: DownloadSectionProps): ReactNode => {
  return (
    <div
      className={classNames({
        [css(styles.dark)]: darkBackground,
        [css(styles.container)]: true,
      })}>
      <div className={'o-page-wrap  o-page-wrap--xsmall'}>
        <TextHeader className={css(styles.header)} type={'h2'}>
          Dokumenty do pobrania
        </TextHeader>
        <ul style={{ listStyleType: 'none' }}>
          {downloads
            .filter((item: Object): boolean => {
              if (item.models) {
                const modelName = `${model.name
                  .charAt(0)
                  .toUpperCase()}${model.name.slice(1).toLowerCase()}`
                if (!item.models.includes(modelName)) {
                  return false
                }
              }
              return true
            })
            .map((item: Object): ReactNode => (
              <li key={item.title} className={css(styles.item)}>
                <DownloadLink
                  title={item.title}
                  path={item.path}
                  onDownload={(): void => onDownloaded(item)}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 56,
    paddingBottom: 110,
  },
  dark: {
    backgroundColor: StyleGuide.Colors.gray,
  },
  item: {
    marginBottom: 10,
  },
  header: {
    paddingBottom: 32,
    textAlign: 'center',
  },
})

export default memo(DownloadSection)
