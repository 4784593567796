import { BrandsAction } from '../constants/BrandsAction';

type ClearBrandsActionType = {
  type: typeof BrandsAction.CLEAR;
};

type SetBrandsActionType = {
  type: typeof BrandsAction.SET;
  brands: Object;
};

type SetCurrentBrandActionType = {
  type: typeof BrandsAction.SET_CURRENT;
  name: string;
};

export type BrandsActionType =
  | ClearBrandsActionType
  | SetBrandsActionType
  | SetCurrentBrandActionType;

const clearBrands = (): BrandsActionType => ({
  type: BrandsAction.CLEAR,
});

const setBrands = (brands: Object): BrandsActionType => ({
  type: BrandsAction.SET,
  brands,
});

const setCurrentBrand = (name: string): BrandsActionType => ({
  type: BrandsAction.SET_CURRENT,
  name,
});

export { clearBrands, setBrands, setCurrentBrand };
