import React, { memo } from 'react';
import type { ReactNode } from 'react';

type DownloadLinkProps = {
  title: string;
  path: string;
  onDownload?: Function;
};

const DownloadLink = ({
  title,
  path,
  onDownload = () => {},
}: DownloadLinkProps): ReactNode => (
  <a
    href={path}
    target='_blank'
    rel='noopener noreferrer'
    className='c-link  c-download-link'
    onClick={() => onDownload()}>
    <i className='c-link__icon  c-icon  c-icon--[semantic-download]' />
    <span className='c-link__body'>
      <span className='c-link__effect'>{title}</span>
    </span>
  </a>
);

export default memo(DownloadLink);
