import React from 'react';
import { CarProtectDownloadItem } from '../../../types/CarProtectProdcut';
import { DownloadLink, TextHeader } from '../../common';
import { css } from 'aphrodite';
import styles from './CarProtectDownloads.styles';

interface CarProtectDownloadsProps {
  downloads: CarProtectDownloadItem[];
}

const CarProtectDownloads = ({ downloads }: CarProtectDownloadsProps) => {
  return (
    <div className={css(styles.container)}>
      <div className={'o-page-wrap  o-page-wrap--xsmall'}>
        <TextHeader className={css(styles.header)} type={'h3'}>
          Dokumenty do pobrania
        </TextHeader>
      </div>

      <ul style={{ listStyleType: 'none' }}>
        {downloads.map((item) => (
          <li key={item.title} className={css(styles.item)}>
            <DownloadLink title={item.title} path={item.path} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CarProtectDownloads;
