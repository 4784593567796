import { createSelector } from 'reselect';

import { selectOfferModel, selectOfferType } from './offerSelectors';

const selectConfigProducts = (state: any): Array<any> => state.config.products;

const selectConfigEngines = (state: any): Array<any> => state.config.engines;

const selectAllBrands = (state: any): string => state.brands.all;
const selectCurrentBrand = (state: any): string => state.brands.current || '';
const selectCurrentBrandInfo = (state: any): any => {
  const brand = state.brands.all[state.brands.current];
  return brand && brand.info;
};

const selectCurrentModels = (state: any): Array<any> => {
  return state.brands.all[state.brands.current]
    ? state.brands.all[state.brands.current].models
    : [];
};

const makeCurrentProductsSelector = createSelector(
  [selectOfferModel, selectOfferType, selectConfigProducts],
  (model: any, type: any, products: Array<any>): Array<any> => {
    if (model && products) {
      return model.products
        .filter((item: any): any => {
          return (
            !item.excluded &&
            !!products[item.id] &&
            products[item.id].types.includes(type.type)
          );
        })
        .map((item: any): any => products[item.id]);
    }
    return [];
  }
);

const selectMyBrand = (state: any): string => state.auth.data.name;

const selectHasData = (state: any): boolean => Boolean(state.brands.all.length > 0);
export {
  makeCurrentProductsSelector,
  selectConfigEngines,
  selectAllBrands,
  selectCurrentBrand,
  selectCurrentBrandInfo,
  selectCurrentModels,
  selectMyBrand,
  selectHasData,
};
