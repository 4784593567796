import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import type { Action, Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { StyleSheet, css } from 'aphrodite/no-important';

import { setCurrentBrand } from '../redux/actions/BrandsActions';
import {
  setModel,
  setProduct,
  setType,
  setYear,
  setEngine,
} from '../redux/actions/OfferActions';
import {
  makeCurrentProductsSelector,
  selectAllBrands,
  selectConfigEngines,
  selectCurrentBrand,
  selectCurrentBrandInfo,
  selectCurrentModels,
  selectMyBrand,
} from '../redux/selectors/configurationSelectors';
import {
  selectOfferEngine,
  selectOfferModel,
  selectOfferProduct,
  selectOfferType,
} from '../redux/selectors/offerSelectors';

import { Button, Text, TextHeader } from '../components/common';
import { Dropdown } from '../components/inputs';
import { BrandChooserModal } from '../components/modals';

import { getLogo } from '../utils/getLogo';

const types = [
  {
    name: 'Samochód nowy z leasingiem VWFS',
    id: 1,
    isNew: true,
    type: 'new-funding',
  },
  {
    name: 'Samochód nowy z kredytem VWFS',
    id: 2,
    isNew: true,
    type: 'new',
  },
  {
    name: 'Samochód nowy bez finansowania VWFS',
    id: 3,
    isNew: true,
    type: 'new',
  },
  {
    name: 'Samochód używany z leasingiem VWFS',
    id: 4,
    isNew: false,
    type: 'used-funding',
  },
  {
    name: 'Samochód używany z kredytem VWFS',
    id: 5,
    isNew: false,
    type: 'used-credit',
  },
  {
    name: 'Samochód używany bez finansowania VWFS',
    id: 6,
    isNew: false,
    type: 'used',
  },
];

type ConfigurationViewProps = {
  brands: any;
  currentBrand: string;
  currentBrandInfo: any;
  currentModels: Array<any>;
  currentProducts: Array<any>;
  engines: Array<any>;
  history: any;
  model: any;
  myBrand: string;
  product: any;
  setCurrentBrand: Function;
  setModel: Function;
  setProduct: Function;
  setType: Function;
  setYear: Function;
  setEngine: Function;
  year: any;
  engine: number;
};

const ConfigurationView = ({
  brands,
  currentBrand,
  currentBrandInfo,
  currentModels,
  currentProducts = [],
  engines,
  history,
  model,
  myBrand,
  product,
  setCurrentBrand,
  setModel,
  setProduct,
  setType,
  setYear,
  setEngine,
}: ConfigurationViewProps): ReactNode => {
  const [selectedModel, setSelectedModel] = useState(0);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [selectedEngine, setSelectedEngine] = useState(0);
  const [isBrandChooser, setIsBrandChooser] = useState(false);

  const [isYearChooser, setIsYearChooser] = useState(false);
  const [yearOptions, setYearOptions] = useState(null);
  const [engineOptions, setEngineOptions] = useState(null);
  const [selectedYear, setSelectedYear] = useState(0);

  const [modelProductsMap, setModelProductsMap] = useState(null);

  useEffect(() => {
    if (model) {
      const map = model.products.reduce(
        (acc: any, item: any): any => ({
          ...acc,
          [item.id]: item,
        }),
        {}
      );
      setModelProductsMap(map);
    }
  }, [model]);

  const getYearsFromProducts = useCallback(
    (modelProductsMap: any): any[] => {
      return currentProducts
        .map((item: any, idx: number): any[] => {
          return modelProductsMap[item.id].years.map((year: string): any => ({
            year,
            productIdx: idx,
          }));
        })
        .flatMap((item: any[]): any[] => item)
        .sort((a: any, b: any): number => {
          if (a.year < b.year) {
            return 1;
          }
          if (a.year > b.year) {
            return -1;
          }
          return 0;
        });
    },
    [currentProducts]
  );

  const getTypes = useCallback((): Array<any> => {
    if (currentBrand !== myBrand || myBrand !== 'audi') {
      return types.filter((item: any): any => !item.isNew);
    }
    return types;
  }, [currentBrand, myBrand]);

  useEffect(() => {
    if (isYearChooser && currentProducts && currentProducts.length > 0) {
      const engineIds = (modelProductsMap || {})[
        currentProducts[selectedProduct].id
      ]?.engineIds;
      if (engineIds && engineIds.length > 0) {
        const engineOptions = engineIds.map((id: number): any => engines[id]);
        setEngineOptions(engineOptions);
        setEngine(engineIds[0]);
      } else {
        setEngineOptions(null);
        setEngine(null);
      }
    } else {
      setEngine(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isYearChooser,
    selectedYear,
    modelProductsMap,
    currentProducts,
    currentBrand,
  ]);

  useEffect(() => {
    if (currentModels && currentModels.length > 0) {
      setModel(currentModels[0]);
    }
  }, [currentModels, setModel]);

  useEffect(() => {
    if (currentProducts && currentProducts.length > 0) {
      setProduct(currentProducts[0]);
    }
  }, [currentProducts, setProduct]);

  useEffect(() => {
    setType(getTypes()[0]);
    setSelectedType(0);
  }, [currentBrand, setType, getTypes, setSelectedType]);

  useEffect(() => {
    if (!modelProductsMap) {
      return;
    }

    const shouldRenderYearChooser: boolean = currentProducts.reduce(
      (acc: boolean, item: any): boolean => {
        if (!acc) {
          return acc;
        }
        if (
          modelProductsMap[item.id]?.years &&
          modelProductsMap[item.id].years.length
        ) {
          return true;
        }
        return false;
      },
      true
    ) as boolean;
    setIsYearChooser(shouldRenderYearChooser);
    if (shouldRenderYearChooser) {
      const years = getYearsFromProducts(modelProductsMap);
      setYear(years[0]);
      setYearOptions(years);
      setSelectedYear(0);
    } else {
      setYear(null);
      setYearOptions(null);
    }
  }, [
    currentProducts,
    modelProductsMap,
    getYearsFromProducts,
    setYear,
    setYearOptions,
  ]);

  const getModelName = (item: any): string => item.name;

  const getTypeName = (item: any): string => item.name;

  const getProductName = (item: any): string => item.name;

  const renderModelChooser = (): ReactNode => (
    <Dropdown
      label={'Wybierz model'}
      mapper={getModelName}
      onChange={(idx: number) => {
        setModel(currentModels[idx]);
        setSelectedModel(idx);
      }}
      options={currentModels}
      value={selectedModel || ''}
    />
  );

  const renderYearChooser = (): ReactNode => {
    // const productsMap = model.products.reduce(
    //   (acc: any, item: any): any => ({
    //     ...acc,
    //     [item.id]: item,
    //   }),
    //   {}
    // );
    return (
      <Dropdown
        label={'Wybierz rok produkcji'}
        mapper={(obj: any): string => obj.year}
        onChange={(idx: number) => {
          setProduct(currentProducts[yearOptions[idx].productIdx]);
          setSelectedProduct(yearOptions[idx].productIdx);
          setYear(yearOptions[idx]);
          setSelectedYear(idx);
        }}
        options={yearOptions}
        value={selectedYear || ''}
      />
    );
  };

  const renderProductChooser = (): ReactNode => {
    if (currentProducts.length < 2) {
      return null;
    }
    if (isYearChooser) {
      return renderYearChooser();
    }
    return (
      <Dropdown
        label={'Wybierz opcję'}
        mapper={getProductName}
        onChange={(idx: number) => {
          setProduct(currentProducts[idx]);
          setSelectedProduct(idx);
        }}
        options={currentProducts}
        value={selectedProduct || ''}
      />
    );
  };

  const renderEngineChooser = (): ReactNode => {
    if (
      engineOptions === null ||
      engineOptions.length === 0 ||
      currentBrand === 'vwd'
    ) {
      return null;
    }
    return (
      <Dropdown
        label={'Wybierz silnik'}
        mapper={(obj: any): string => obj.label}
        onChange={(idx: number) => {
          setSelectedEngine(idx);
          setEngine(engineOptions[idx].id);
        }}
        options={engineOptions}
        value={selectedEngine || ''}
      />
    );
  };

  const renderTypeChooser = (): ReactNode => (
    <Dropdown
      label={'Wybierz rodzaj oferty'}
      mapper={getTypeName}
      onChange={(idx: number) => {
        setType(getTypes()[idx]);
        setSelectedType(idx);
      }}
      options={getTypes()}
      value={selectedType || ''}
    />
  );

  return (
    <div className={css(styles.container)}>
      <Helmet>
        <title>{`Oferta Pakietów Usług Serwisowych ${currentBrand.toUpperCase()}`}</title>
      </Helmet>
      <div className={css(styles.brandContainer)}>
        <TextHeader type={'h2'}>{`Wybrana marka pojazdu: ${
          currentBrandInfo && currentBrandInfo.displayName
        }`}</TextHeader>
        <img className={css(styles.logo)} src={getLogo(currentBrand)} alt='' />
        <Button
          onClick={(): void => setIsBrandChooser(true)}
          variant={'secondary'}>
          {'Zmień markę'}
        </Button>
      </div>
      <TextHeader type={'h2'}>{'Wybór modelu i produktu'}</TextHeader>
      {currentBrand === 'cupra' && (
        <Text className={css(styles.info)}>
          {
            'Uprzejmie informujemy, że trwa aktualizacja cenników. Oferty dla samochodów używanych nie są chwilowo dostępne. Przepraszamy za utrudnienia.'
          }
        </Text>
      )}
      <>
        <div className={css(styles.modelContainer)}>{renderModelChooser()}</div>
        <div className={css(styles.typeContainer)}>{renderTypeChooser()}</div>
        {currentProducts.length > 1 && (
          <div className={css(styles.productsContainer)}>
            {renderProductChooser()}
          </div>
        )}
        <div className={css(styles.engineContainer)}>
          {renderEngineChooser()}
        </div>
        <Button
          onClick={() => {
            history.push('/product');
            window.dataLayer.push({
              event: 'GAEvent',
              eventCategory: 'Oferta',
              eventAction: 'View',
              eventLabel: '', // puste
              currentBrand: currentBrand,
              loggedBrand: myBrand,
              modelName: model.name,
              offerName: getTypes()[selectedType].name,
              period: '', // może pozostać puste
              limit: '', // może pozostać puste
              price: '', // może pozostać puste
              eventNonInteraction: false,
            });
          }}
          disabled={currentProducts.length === 0}>
          {'Wyświetl ofertę'}
        </Button>
      </>
      {isBrandChooser && (
        <BrandChooserModal
          brands={Object.keys(brands).map((item: string): any => ({
            ...brands[item].info,
            key: item,
          }))}
          onChoose={(brand: string) => {
            setIsBrandChooser(false);
            setCurrentBrand(brand);
            window.dataLayer.push({
              event: 'GAEvent',
              eventCategory: 'Wybór marki',
              eventAction: 'Click',
              eventLabel: '', // pozostaje puste
              currentBrand: currentBrand,
              choosedBrand: brand,
              loggedBrand: myBrand,
              eventNonInteraction: false,
            });
          }}
          onClose={(): void => setIsBrandChooser(false)}
        />
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 100,
  },
  brandContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 30,
    paddingBottom: 120,
  },
  modelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 60,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
  },
  productsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 40,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
  },
  typeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 40,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
  },
  engineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 40,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 100,
    width: '100%',
  },
  productsChooser: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 40,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
  },
  logo: {
    maxHeight: 200,
    width: 'auto',
    paddingTop: 30,
    paddingBottom: 50,
  },
  info: {
    textAlign: 'center',
  },
});

const mapStateToProps = (state: any): any => {
  const selected = createStructuredSelector({
    brands: selectAllBrands,
    engines: selectConfigEngines,
    currentBrand: selectCurrentBrand,
    currentBrandInfo: selectCurrentBrandInfo,
    currentModels: selectCurrentModels,
    model: selectOfferModel,
    myBrand: selectMyBrand,
    product: selectOfferProduct,
    type: selectOfferType,
    engine: selectOfferEngine,
  })(state);

  return {
    currentProducts: makeCurrentProductsSelector(state),
    ...selected,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): any => {
  const actions = {
    setCurrentBrand,
    setModel,
    setProduct,
    setType,
    setYear,
    setEngine,
  };
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationView);
