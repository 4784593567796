import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingTop: 56,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  }
});

export default styles;
