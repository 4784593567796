import React, { Fragment, memo } from 'react'
import type { ReactNode } from 'react'

import { StyleSheet, css } from 'aphrodite/no-important'

import { Button, ModalLarge, Text, TextHeader } from '../common'
import { formatPrice } from '../../utils/formatters/formatPrice'

type ProducConfirmationModalProps = {
  brandInfo: Object,
  onConfirm: Function,
  onClose: Function,
  onPrint: Function,
  option: Object,
  model: Object,
  plans: Array<Object>,
  product: Object,
  productionYear: Object | null,
}

const ProducConfirmationModal = ({
  brandInfo,
  onConfirm,
  onClose,
  onPrint,
  option,
  model,
  product,
  productionYear,
  plans,
}: ProducConfirmationModalProps): ReactNode => {

  const currentPlan = plans.find(
    (item: Object): Object => item.id === option.planId
  )

  const getProductLabel = (): string => {
    if (currentPlan && productionYear) {
      return `${currentPlan.name} - rok produkcji ${productionYear.year}`
    } else if (currentPlan) {
      return currentPlan.name
    }
    return ''
  }

  const renderFooter = (): ReactNode => (
    <div className='c-modal__actions'>
      <div className='c-modal__actions__item'>
        <Button onClick={(): void => onPrint(option)} variant='primary'>
          <span>{'Drukuj'}</span>
        </Button>
      </div>
      <div className='c-modal__actions__item'>
        <Button onClick={onConfirm} variant='secondary'>
          <span>{'Zamknij'}</span>
        </Button>
      </div>
    </div>
  )

  return (
    <ModalLarge onClose={onClose} renderFooter={renderFooter}>
      <TextHeader type={'h2'}>{'Wybrany produkt:'}</TextHeader>
      <div>
        <TextHeader className={css(styles.headingSmallMargin)}>{`${
          brandInfo && brandInfo.displayName
        } ${model.name}`}</TextHeader>
        <TextHeader className={css(styles.headingSmallMargin)}>
          {getProductLabel()}
        </TextHeader>
        <TextHeader>{`${option.age} mies. ${option.distance ? `${option.distance} tys. km` : `- ${option.type}`}`}</TextHeader>
      </div>
      <div className={css(styles.pricesContainer)}>
        <TextHeader className={css(styles.priceTitle)}>{'CENA'}</TextHeader>
        <TextHeader className={css(styles.price)} type={'h2'}>
          {`${formatPrice(option.price)} zł`}
        </TextHeader>
        {option.installment && !isNaN(option.installment) && (
          <Fragment>
            <div style={{ width: '20px' }} />
            <TextHeader className={css(styles.priceTitle)}>{'RATA'}</TextHeader>
            <TextHeader className={css(styles.price)} type={'h2'}>
              {`${formatPrice(option.installment)} zł`}
            </TextHeader>
          </Fragment>
        )}
      </div>
      <div className={css(styles.contentContainer)}>
        <Text>{`${product.action.message}.`}</Text>
      </div>
    </ModalLarge>
  )
}

const styles = StyleSheet.create({
  contentContainer: {},
  headingSmallMargin: {
    marginBottom: 6,
  },
  pricesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  priceTitle: {
    paddingTop: 0,
    paddingBottom: 3,
  },
  price: {
    padding: '0 10px',
  },
})

export default memo(ProducConfirmationModal)
