import { StyleSheet } from 'aphrodite';
import StyleGuide from '../../../styles/StyleGuide';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
    width: '100%',
    height: 300,
    cursor: 'pointer',

    ':hover': {
      backgroundColor: StyleGuide.Colors.gray,
    },
  },
  image: {
    padding: 0,
    maxHeight: 180,
    objectFit: 'cover',
  },
  item: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyItems: 'top',
    justifyContent: 'center',
  },
  title: {
    paddingBottom: 0,
    textAlign: 'center',
  },
  itemIcon: {
    minWidth: 16,
    minHeight: 16,
    width: 16,
    height: 16,
    backgroundColor: StyleGuide.Colors.secondary,
    marginTop: 6,
    marginRight: 9,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    fontWeight: 800,
    fontSize: 16,
  },
});

export default styles;
