import React, { Component } from 'react';
import type { ReactNode } from 'react';

import NavItem from './NavItem';

type TopMenuProps = {
  history: History & { push(path: string): void };
  location: Location;
  logout: Function;
  user: string;
};

type TopMenuState = {
  menuState: Object;
};

class TopMenu extends Component<TopMenuProps, TopMenuState> {
  state = {
    menuState: {},
  };

  renderItem(title: string, key: string): ReactNode {
    const { menuState } = this.state;
    return (
      <li className='c-site-nav__item js-dropdown'>
        <button
          onClick={(): void =>
            this.setState({
              menuState: { ...menuState, [key]: !menuState[key] },
            })
          }
          className='c-site-nav__link js-dropdown__button'
          aria-expanded='false'>
          <span className='c-site-nav__link-label'>{title}</span>

          <i className='c-site-nav__link-icon' aria-hidden='true' />
        </button>
        <nav
          className='c-site-nav-dropdown  js-dropdown__panel'
          aria-hidden={!menuState[key]}>
          <ul className='c-site-nav-dropdown__list'>
            <li className='c-site-nav-dropdown__title'>{title}</li>
            {this.renderSubItem(
              'Ogólne Warunki Ubezpieczenia',
              '/files/Zal_1_OWU.pdf'
            )}
            {this.renderSubItem(
              'Karta Produktu (IPID)',
              '/files/IPID_VW_IU_3_1.1.2020.pdf'
            )}
            {this.renderSubItem(
              'Informacja o dystrybutorze VWSU',
              '/files/Zal_2_Info_VWSU.pdf'
            )}
          </ul>
        </nav>
      </li>
    );
  }

  renderSubItem(title: string, link: string): ReactNode {
    return (
      <li className='c-site-nav-dropdown__item'>
        <a
          className='c-site-nav-dropdown__link'
          target='_blank'
          rel='noopener noreferrer'
          href={link}>
          {title}
        </a>
      </li>
    );
  }

  render(): ReactNode {
    const { history, location, logout, user } = this.props;
    const { pathname } = location;

    return (
      <div className='c-header__nav'>
        <div className='c-site-nav  js-site-nav'>
          <div
            className='c-site-nav__panel  js-site-nav__navs'
            aria-hidden='true'>
            <nav className='c-site-nav__navs' aria-label='primary'>
              <ul className='c-site-nav__nav'>
                {pathname === '/product' && (
                  <NavItem onClick={(): void => history.push('/configuration')}>
                    ZMIANA KONFIGURACJI
                  </NavItem>
                )}
                {(pathname === '/configuration' ||
                  pathname.startsWith('/car_protect')) && (
                  <>
                    <NavItem
                      onClick={(): void =>
                        history.push('/car_protect')
                      }>
                      OCHRONA POGWARANCYJNA
                    </NavItem>
                    <NavItem
                      onClick={(): void => history.push('/configuration')}>
                      PAKIETY SERWISOWE
                    </NavItem>
                  </>
                )}
                <NavItem
                  onClick={(): void => logout()}>{`WYLOGUJ (${user})`}</NavItem>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default TopMenu;
