export const LocalStorageKeys = {
  TOKEN: 'token',
}

class LocalStorage {
  static get(key: string): Any {
    return localStorage.getItem(key)
  }

  static set(key: string, value: Any) {
    localStorage.setItem(key, value)
  }
}

export default LocalStorage
