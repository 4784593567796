import React, { memo } from 'react'
import type { ReactNode } from 'react'

import { StyleSheet, css } from 'aphrodite/no-important'
import classNames from 'classnames'

import { TextHeader } from '../common'
import StyleGuide from '../../styles/StyleGuide'

type InfoSectionProps = {
  darkBackground?: boolean,
  title: string,
  text: Array<Object>,
}

const InfoSection = ({
  darkBackground = false,
  title,
  text,
}: InfoSectionProps): ReactNode => {
  const renderEntry = (text: string, idx: number): ReactNode => (
    <li key={idx} className='c-stepper-vertical__item  '>
      <p style={{ whiteSpace: 'break-spaces' }}>{text}</p>
    </li>
  )
  return (
    <div
      className={classNames({
        [css(styles.dark)]: darkBackground,
      })}>

      <div
        className={`${css(styles.container)} o-page-wrap  o-page-wrap--xsmall`}>
        <div className={css(styles.header)}>
          <TextHeader type={'h2'}>Proces zakupu</TextHeader>
        </div>
        <ol className={'c-stepper-vertical  c-stepper-vertical--numbered'}>
          {text.map((item: Object, idx: number): ReactNode =>
            renderEntry(item.info, idx)
          )}
        </ol>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 56,
    paddingBottom: 70,
  },
  dark: {
    backgroundColor: StyleGuide.Colors.gray,
  },
  content: {
    columnCount: 2,
    columnGap: '30px',
    whiteSpace: 'pre-line',
  },
  header: {
    paddingBottom: 16,
    textAlign: 'center',
  },
})

export default memo(InfoSection)
