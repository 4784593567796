import React, { Component } from 'react'
import type { ReactNode } from 'react'

import classNames from 'classnames'

type PageContainerProps = {
  children: ReactNode,
  wide?: boolean,
}

class PageContainer extends Component<PageContainerProps> {
  render(): ReactNode {
    const { children, wide } = this.props
    return (
      <main className='o-main  '>
        <section>
          <div
            className={classNames({
              'o-page-wrap  o-page-wrap--xsmall': !wide,
            })}>
            {children}
          </div>
        </section>
      </main>
    )
  }
}

export default PageContainer
