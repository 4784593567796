import CarProtectProductView from '../views/CarProtectProductView'
import CarProtectView from '../views/CarProtectView'
import ConfigurationView from '../views/ConfigurationView'
import ProductPresentationView from '../views/ProductPresentationView'

const mainRoutes: Array<Object> = [
  {
    component: ConfigurationView,
    path: '/configuration',
    header: true,
    footer: true,
    isWide: false,
  },
  {
    component: CarProtectView,
    path: '/car_protect',
    header: true,
    footer: true,
    isWide: false,
  },
  {
    component: CarProtectProductView,
    path: '/car_protect_product',
    header: true,
    footer: true,
    isWide: true,
  },
  {
    component: ProductPresentationView,
    path: '/product',
    header: true,
    footer: true,
    isWide: false,
  },
]

const Routes = {
  main: mainRoutes,
}

export default Routes
