const generateUUIDPart = (): string => {
  const part: string = `${Math.floor(Math.random() * 100000)}`
  return part.padStart(6, '0')
}

const generateUUID = (): string => {
  const id = `${generateUUIDPart()}-${generateUUIDPart()}-${generateUUIDPart()}`
  return id
}

const generateSimpleUuid = (): string => {
  return generateUUIDPart()
}

export { generateSimpleUuid, generateUUID }
