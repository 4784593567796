import type { ReactNode } from 'react';
import { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { StyleSheet, css } from 'aphrodite/no-important';
import React from 'react';

type TooltipProps = {
  children?: ReactNode;
  text: string;
};

const Tooltip = ({ children, text }: TooltipProps): ReactNode => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div
        data-tip={text}
        data-class={css(styles.tooltipContainer)}
        onMouseEnter={(): void => setIsOpen(true)}
        onMouseLeave={(): void => setIsOpen(false)}>
        <div className={css(styles.container)}>{children}</div>
      </div>
      {isOpen && (
        <ReactTooltip
          effect={'solid'}
          type={'light'}
          place={'bottom'}
          multiline
          borderColor={'#'}
        />
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    cursor: 'pointer',
  },
  tooltipContainer: {
    backgroundColor: 'red',
    boxShadow: '0px 2px 20px rgba(0,0,0,0.3)',
    lineHeight: 1.3,
    borderRadius: '0 !important',
    fontSize: 15,
    maxWidth: 230,
    color: '#4c5356 !important',
    whiteSpace: 'pre-wrap',
  },
});

export default Tooltip;
