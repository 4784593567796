import React, { Fragment } from 'react';
import type { ReactNode } from 'react';

import { StyleSheet, css } from 'aphrodite/no-important';
import classNames from 'classnames';

type TextProps = {
  bold?: boolean;
  children?: ReactNode;
  className?: string;
};

const Text = ({
  bold = false,
  children,
  className,
  ...rest
}: TextProps): ReactNode => {
  const renderBold = (text: ReactNode): ReactNode =>
    bold ? <strong>{text}</strong> : <Fragment>{text}</Fragment>;

  return (
    <p
      className={classNames({
        [css(styles.container)]: true,
        [className]: !!className,
      })}
      {...rest}>
      {renderBold(children)}
    </p>
  );
};

const styles = StyleSheet.create({
  container: {},
});

export default Text;
