import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  container: {
    paddingTop: 56,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
  },
  header: {
    paddingBottom: 16,
    textAlign: 'center',
  },
  cell: {
    borderBottom: '1px solid #adadad',
    cellSpacing: 'none',
    textAlign: 'center',
    verticalAlign: 'top',
    height: 80,
    padding: '8px 8px',
    whiteSpace: 'break-spaces',
  },
  cellLeft: {
    textAlign: 'left',
    verticalAlign: 'center',
  },
  cellHeader: {
    height: '40px',
    fontWeight: 400,
    fontSize: 16,
    paddingBottom: 5,
  },
  wideColumn: {
    textAlign: 'left',
    verticalAlign: 'bottom',
  },
});

export default styles;
