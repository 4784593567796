import type { ReactNode } from 'react'
import React, { Component } from 'react'

import { StyleSheet, css } from 'aphrodite/no-important'
import classNames from 'classnames'

import { generateSimpleUuid } from '../../utils/Uuid'

type DropdownProps = {
  label: string,
  isError?: boolean,
  mapper: Function,
  onChange: Function,
  options: Array<Object>,
  value: number | string,
}


class Dropdown extends Component<DropdownProps> {
  render(): ReactNode {
    const { label, isError, mapper, onChange, options, value } = this.props
    const id = generateSimpleUuid()
    return (
      <div className={`o-fieldset__row ${css(styles.container)}`}>
        <div className='c-form-field  '>
          <label htmlFor='kd4la6uk' className='c-form-field__label'>
            {label}
          </label>
          <div className='c-form-field__box'>
            <div className='c-input  c-input--select'>
              <select
                className={classNames({
                  'c-input__input': true,
                  'is-error': isError,
                })}
                name={id}
                id={id}
                onChange={(e: any): Object => onChange(e.target.value)}
                value={value}>
                {options &&
                  options.map((item: Object, idx: number): ReactNode => (
                    <option key={idx} value={idx}>
                      {mapper ? mapper(item) : item}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
})

export default Dropdown
