import React, { memo } from 'react';
import type { ReactNode } from 'react';

type ModalLargeProps = {
  children: ReactNode;
  onClose?: Function;
  renderFooter?: Function;
  title?: string;
};

const ModalLarge = ({
  children,
  onClose,
  renderFooter,
  title,
}: ModalLargeProps): ReactNode => {
  const renderHeader = (): ReactNode => (
    <header className='c-modal__header'>
      {title && (
        <div className='c-modal__title__wrapper'>
          <h1 className='c-modal__title' id='foo-bar-baz'>
            {title}
          </h1>
        </div>
      )}
      <button
        className='c-modal__close-btn  js-modal-close'
        onClick={() => onClose()}
        type='button'
        aria-label='Close this dialog window.'
      />
    </header>
  );

  return (
    <div
      className='c-modal  c-modal--large'
      aria-hidden='false'
      id='demo-modal'
      tabIndex={-1}>
      <div className='c-modal__overlay  js-modal-close' tabIndex={-1} />

      <div
        className='c-modal__wrapper'
        role='dialog'
        aria-labelledby='foo-bar-baz'>
        {renderHeader()}
        <div className='c-modal__content' role='document'>
          {children}
        </div>

        {renderFooter && (
          <div className='c-modal__footer'>{renderFooter()}</div>
        )}
      </div>
    </div>
  );
};

export default memo(ModalLarge);
