import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

let fonts = {};

const defaultFontOptions = {
  size: 7.3,
  color: rgb(0.3, 0.33, 0.34),
};

const headerFontOptions = {
  size: 11,
  color: rgb(0, 0.27, 0.4),
};

const convertPoint = (
  page: Object,
  x: number,
  y: number
): { x: number; y: number } => {
  return {
    x: x,
    y: page.getHeight() - y,
  };
};

const rescaleImage = (image: Object, sizes: Object): number => {
  const orginalDimensions = image.scale(1);
  if (!sizes) {
    return orginalDimensions;
  }
  const { width, height, maxWidth, maxHeight } = sizes;
  if (width && height) {
    return { ...orginalDimensions, width, height };
  } else if (width) {
    if (
      maxHeight &&
      (orginalDimensions.height * width) / orginalDimensions.width > maxHeight
    ) {
      return {
        ...orginalDimensions,
        width: (orginalDimensions.width * maxHeight) / orginalDimensions.height,
        height: maxHeight,
      };
    }
    return {
      ...orginalDimensions,
      width,
      height: (orginalDimensions.height * width) / orginalDimensions.width,
    };
  }
  if (
    maxWidth &&
    (orginalDimensions.width * height) / orginalDimensions.height > maxWidth
  ) {
    return {
      ...orginalDimensions,
      height: (orginalDimensions.height * maxWidth) / orginalDimensions.width,
      width: maxWidth,
    };
  }
  return {
    ...orginalDimensions,
    height,
    width: (orginalDimensions.width * height) / orginalDimensions.height,
  };
};

// eslint-disable-next-line space-before-function-paren
const createPDF = async (): Object => {
  const pdfDoc = await PDFDocument.create();
  pdfDoc.registerFontkit(fontkit);
  return pdfDoc;
};

const downloadAsPDF = (name: string, byte: any) => {
  var blob = new Blob([byte], { type: 'application/pdf' });
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = name;
  link.download = fileName;
  link.click();
};

// eslint-disable-next-line space-before-function-paren
const loadFont = async (doc: Object, path: string, name: string) => {
  const fontURL: string = path;
  const fontBytes = await fetch(fontURL).then((res: any): void =>
    res.arrayBuffer()
  );
  const font = await doc.embedFont(fontBytes);
  fonts = {
    ...fonts,
    [name]: font,
  };
};

// eslint-disable-next-line space-before-function-paren
const addImage = async (
  doc: any,
  page: any,
  path: string,
  options: Object = {}
) => {
  try {
    const { x, y, height, width, maxWidth, maxHeight } = options;
    const pngImageBytes = await fetch(path).then((res: Object): void =>
      res.arrayBuffer()
    );

    const image = await doc.embedPng(pngImageBytes);
    let dimensions = rescaleImage(image, {
      height,
      width,
      maxWidth,
      maxHeight,
    });
    const drawOptions = {
      ...convertPoint(page, x || 0, dimensions.height + (y || 0)),
      width: dimensions.width,
      height: dimensions.height,
    };
    if (!x) {
      drawOptions.x = page.getWidth() / 2 - dimensions.width / 2;
    }

    page.drawImage(image, drawOptions);
  } catch (e) {
    console.log('error emebdding image');
  }
};

const drawLine = (page: any, start: Object, end: Object, options?: Object) => {
  page.drawLine({
    start: convertPoint(page, start.x, start.y),
    end: convertPoint(page, end.x, end.y),
    ...options,
    // thickness: 2,
    // color: rgb(0.75, 0.2, 0.2),
    // opacity: 0.75,
  });
};

const wrapText = (
  text: string,
  width: number,
  fontType: string = 'normal'
): Array<string> => {
  let result = [];
  const words = text.split(' ');
  let currentWord = 0;
  let currentResult = 0;
  do {
    if (words && words.length > currentWord) {
      result[currentResult] = words[currentWord];
      currentWord += 1;
    }

    while (
      words.length > currentWord &&
      !words[currentWord].startsWith('\n') &&
      fonts[fontType].widthOfTextAtSize(
        `${result[currentResult]} ${words[currentWord]}`,
        defaultFontOptions.size
      ) < width
    ) {
      result[currentResult] = `${result[currentResult]} ${words[currentWord]}`;
      currentWord += 1;
    }
    if (result[currentResult].startsWith('\n')) {
      result[currentResult] = result[currentResult].substring(1);
    }
    currentResult += 1;
  } while (words.length > currentWord);
  return result;
};

const drawText = (
  page: any,
  text: string,
  x: number,
  y: number,
  options?: any = {}
) => {
  if (!text || !page) {
    return;
  }
  const pos: number = convertPoint(page, x, y);
  page.drawText(text, {
    x: pos.x,
    y: pos.y,
    font: fonts[options.type] || fonts.normal,
    ...defaultFontOptions,
    ...options,
  });
};

const drawWrappedText = (
  page: any,
  text: string,
  x: number,
  y: number,
  width: number,
  method: string,
  options?: any = {}
): Array<string> => {
  const chunks = text.split('\n');
  let lines = [];
  chunks.forEach((chunk: string) => {
    lines.push(wrapText(chunk, width));
  });

  lines = lines.flat();
  lines.map((item: string, idx: number, arr: Array<string>): string => {
    let posY = y;
    if (method === 'up') {
      posY = y - (arr.length - 1 - idx) * (options.size + 2);
    } else if (method === 'down') {
      posY = y + idx * (options.size + 2);
    }

    drawText(page, item, x, posY, options);
    return item;
  });
  return lines;
};

const drawHeaderText = (
  page: any,
  text: string,
  x: number,
  y: number,
  options?: any = {}
) => {
  let centeredX;
  if (!x) {
    const textWidth = fonts['bold'].widthOfTextAtSize(
      text,
      options.size || headerFontOptions.size
    );
    centeredX = page.getWidth() / 2 - textWidth / 2;
  }
  drawText(page, text, x || centeredX, y, {
    type: 'bold',
    ...headerFontOptions,
    ...options,
  });
};

const drawTextWithBackground = (
  page: any,
  text: string,
  x: number,
  y: number,
  options?: any = { type: 'normal' }
) => {
  const textWidth = fonts[options.type].widthOfTextAtSize(text, options.size);
  const textHeight = fonts[options.type].heightAtSize(options.size);

  page.drawRectangle({
    ...convertPoint(
      page,
      options.centerX ? x - textWidth / 2 : x,
      y + textHeight + 15
    ),
    width: textWidth + 20,
    height: textHeight + 15,
    color: options.background || rgb(1, 0, 0),
    borderWidth: 0,
  });
  page.drawText(text, {
    ...convertPoint(
      page,
      options.centerX ? x + 10 - textWidth / 2 : x + 10,
      y + textHeight + 1
    ),
    size: options.size,
    font: fonts[options.type],
    color: options.color || rgb(0, 0.53, 0.71),
  });
};

// const drawCenteredText = (text: string) => {}

// const getTextSize = (text: string, size: number): Object => {
//   return font.widthOfTextAtSize(text, size)
// }

export {
  addImage,
  createPDF,
  defaultFontOptions,
  downloadAsPDF,
  drawHeaderText,
  drawLine,
  drawText,
  drawTextWithBackground,
  drawWrappedText,
  headerFontOptions,
  loadFont,
};
