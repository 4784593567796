import { StyleSheet } from 'aphrodite';
import StyleGuide from '../../../styles/StyleGuide';

const styles = StyleSheet.create({
  container: {
    paddingTop: 56,
    paddingBottom: 110,
    width: '100%',
  },
  dark: {
    backgroundColor: StyleGuide.Colors.gray,
  },
  item: {
    marginBottom: 10,
  },
  header: {
    paddingBottom: 32,
    textAlign: 'center',
  },
})

export default styles;
