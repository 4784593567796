import type { OfferActionType } from '../actions/OfferActions';
import { OfferAction } from '../constants/OfferAction';

type OfferStateType = {
  model?: any;
  product?: any;
  option?: any;
  type?: any;
  year?: any;
  engine?: number;
};

const initialState: OfferStateType = {
  model: null,
  product: null,
  option: null,
  type: null,
  year: null,
  engine: null,
};

const offerReducer = (
  state: OfferStateType = initialState,
  action: OfferActionType
): OfferStateType => {
  switch (action.type) {
    case OfferAction.SET_MODEL:
      return {
        ...state,
        model: action.model,
      };
    case OfferAction.SET_OPTION:
      return {
        ...state,
        option: action.option,
      };
    case OfferAction.SET_PRODUCT:
      return {
        ...state,
        product: action.product,
      };
    case OfferAction.SET_TYPE:
      return {
        ...state,
        type: action.option,
      };
    case OfferAction.SET_YEAR:
      return {
        ...state,
        year: action.option,
      };
    case OfferAction.SET_ENGINE:
      return {
        ...state,
        engine: action.engine,
      };
    case OfferAction.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default offerReducer;

export type OfferState = OfferStateType;
