import { OfferAction } from '../constants/OfferAction';

type ClearOfferActionType = {
  type: typeof OfferAction.CLEAR;
};

type SetOfferModelActionType = {
  type: typeof OfferAction.SET_MODEL;
  model: any;
};

type SetOfferOptionActionType = {
  type: typeof OfferAction.SET_OPTION;
  option: any;
};

type SetOfferProductActionType = {
  type: typeof OfferAction.SET_PRODUCT;
  product: any;
};

type SetOfferTypeActionType = {
  type: typeof OfferAction.SET_TYPE;
};

type SetOfferYearActionType = {
  type: typeof OfferAction.SET_YEAR;
};

type SetOfferEngineActionType = {
  type: typeof OfferAction.SET_ENGINE;
  engine: number;
};

export type OfferActionType =
  | SetOfferModelActionType
  | SetOfferOptionActionType
  | SetOfferProductActionType
  | SetOfferTypeActionType
  | SetOfferYearActionType
  | ClearOfferActionType
  | SetOfferEngineActionType;

const clearOffer = (): OfferActionType => ({
  type: OfferAction.CLEAR,
});

const setModel = (model: Object): OfferActionType => ({
  type: OfferAction.SET_MODEL,
  model,
});

const setOption = (option: Object): OfferActionType => ({
  type: OfferAction.SET_OPTION,
  option,
});

const setProduct = (product: Object): OfferActionType => ({
  type: OfferAction.SET_PRODUCT,
  product,
});

const setType = (option: Object): OfferActionType => ({
  type: OfferAction.SET_TYPE,
  option,
});

const setYear = (option: Object): OfferActionType => ({
  type: OfferAction.SET_YEAR,
  option,
});


const setEngine = (engine: number): OfferActionType => ({
  type: OfferAction.SET_ENGINE,
  engine,
});
export { clearOffer, setModel, setOption, setProduct, setType, setYear, setEngine };
