import type { ReactNode } from 'react';
import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import type { Action, Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { css } from 'aphrodite/no-important';

import { setCurrentBrand } from '../../redux/actions/BrandsActions';
import {
  selectAllBrands,
  selectCurrentBrand,
  selectCurrentBrandInfo,
  selectMyBrand,
} from '../../redux/selectors/configurationSelectors';

import { Button, TextHeader } from '../../components/common';
import { BrandChooserModal } from '../../components/modals';

import { getLogo } from '../../utils/getLogo';
import styles from './CarProtectView.styles';
import { makeCurrentBrandCarProtectProductsSelector } from '../../redux/selectors/carProtectSelectors';
import { CarProtectProdcut } from '../../types/CarProtectProdcut';
import CarProtectCard from '../../components/products/CarProtectCard';
import { setCurrentCarProtect } from '../../redux/actions/CarProtectActions';

type CarProtectViewProps = {
  brands: any;
  currentBrand: string;
  currentBrandInfo: any;
  history: any;
  myBrand: string;
  setCurrentBrand: Function;
  setCurrentCarProtect: Function;
  carProtectProducts: CarProtectProdcut[];
};

const CarProtectView = ({
  brands,
  currentBrand,
  currentBrandInfo,
  carProtectProducts,
  history,
  myBrand,
  setCurrentBrand,
  setCurrentCarProtect,
}: CarProtectViewProps): ReactNode => {
  const [isBrandChooser, setIsBrandChooser] = useState(false);

  const onProductSelected = (product: CarProtectProdcut): void => {
    setCurrentCarProtect(product);
    history.push('/car_protect_product');
  };

  return (
    <div className={css(styles.container)}>
      <Helmet>
        <title>{`Oferta Pakietów Usług Serwisowych ${currentBrand.toUpperCase()}`}</title>
      </Helmet>
      <div className={css(styles.brandContainer)}>
        <TextHeader type={'h2'}>{`Wybrana marka pojazdu: ${
          currentBrandInfo && currentBrandInfo.displayName
        }`}</TextHeader>
        <img className={css(styles.logo)} src={getLogo(currentBrand)} alt='' />
        <Button
          onClick={(): void => setIsBrandChooser(true)}
          variant={'secondary'}>
          {'Zmień markę'}
        </Button>
      </div>
      <TextHeader type={'h2'}>{'Wybór produktu'}</TextHeader>
      <div className={css(styles.productsContainer)}>
        {carProtectProducts.map((product: CarProtectProdcut, idx: number) => (
          <CarProtectCard
            key={`${product.brand}-${idx}`}
            product={product}
            onSelect={onProductSelected}
          />
        ))}
      </div>
      {isBrandChooser && (
        <BrandChooserModal
          brands={Object.keys(brands).map((item: string): any => ({
            ...brands[item].info,
            key: item,
          }))}
          onChoose={(brand: string) => {
            setIsBrandChooser(false);
            setCurrentBrand(brand);
            window.dataLayer.push({
              event: 'GAEvent',
              eventCategory: 'Wybór marki',
              eventAction: 'Click',
              eventLabel: '', // pozostaje puste
              currentBrand: currentBrand,
              choosedBrand: brand,
              loggedBrand: myBrand,
              eventNonInteraction: false,
            });
          }}
          onClose={(): void => setIsBrandChooser(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: any): any => {
  const selected = createStructuredSelector({
    brands: selectAllBrands,
    currentBrand: selectCurrentBrand,
    currentBrandInfo: selectCurrentBrandInfo,
    myBrand: selectMyBrand,
  })(state);

  return {
    carProtectProducts: makeCurrentBrandCarProtectProductsSelector(state),
    ...selected,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): any => {
  const actions = {
    setCurrentBrand,
    setCurrentCarProtect,
  };
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CarProtectView);
