import React, { Component } from 'react'
import type { ChangeEventHandler, ReactNode } from 'react'

import classNames from 'classnames'

import { generateSimpleUuid } from '../../utils/Uuid'

type TextFieldProps = {
  errorLabel?: string,
  label: string,
  isError?: boolean,
  onChange: ChangeEventHandler<HTMLInputElement>,
  pattern?: any,
  placeholder?: string,
  type?: string,
  value: string,
}

class TextField extends Component<TextFieldProps> {
  render(): ReactNode {
    const { label, isError, onChange, placeholder, type, value } = this.props
    const id = generateSimpleUuid()
    return (
      <div className='o-fieldset__row'>
        <div className='o-layout'>
          <div className='o-layout__item  u-1/1@xs'>
            <div className='c-form-field  '>
              <label htmlFor={id} className='c-form-field__label'>
                {label}
              </label>
              <div className='c-form-field__box'>
                <div className='c-input  '>
                  <input
                    className={classNames({
                      'c-input__input': true,
                      'is-error': isError,
                    })}
                    id={id}
                    type={type || 'text'}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TextField
