import React from 'react';
import { CarProtectProdcut } from '../../../types/CarProtectProdcut';
import { css } from 'aphrodite';
import styles from './CarProtectCard.styles';
import { Text } from '../../common';

interface CarProtectCardProps {
  product: CarProtectProdcut;
  onSelect: (product: CarProtectProdcut) => void;
}

const CarProtectCard = ({ product, onSelect }: CarProtectCardProps) => {
  const { title, image } = product;

  return (
    <div
      className={`c-card ${css(styles.container)}`}
      onClick={() => onSelect(product)}>
      <img className={css(styles.image)} src={image} alt='' />
      <h5 className={css(styles.title)}>{title}</h5>
      <div className={css(styles.item)}>
        <div className={css(styles.itemIcon)}>
          <span style={{ width: '100%', textAlign: 'center' }}>{'>'}</span>
        </div>
        <Text>więcej</Text>
      </div>
    </div>
  );
};

export default CarProtectCard;
