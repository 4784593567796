import React from 'react'
import type { ReactNode } from 'react'

import classNames from 'classnames'
import { StyleSheet, css } from 'aphrodite/no-important'

type ButtonProps = {
  children?: string | ReactNode,
  disabled?: boolean,
  onClick?: Function,
  variant?: string,
}

const Button = ({
  children,
  disabled = false,
  onClick,
  variant,
}: ButtonProps): ReactNode => {
  const bgStyle = css(styles.secondaryButton)
  return (
    <button
      className={classNames({
        'c-btn': true,
        'c-btn--secondary': variant === 'secondary',
        [bgStyle]: variant === 'secondary',
      })}
      disabled={disabled}
      style={{ minWidth: 200 }}
      onClick={!disabled ? () => onClick() : undefined}>
      <span className='c-btn__text'>{children}</span>
    </button>
  )
}

const styles = StyleSheet.create({
  secondaryButton: {
    backgroundColor: 'white !important',
  },
})

export default Button
