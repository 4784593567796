import { BrandsAction } from '../constants/BrandsAction'
import type { BrandsActionType } from '../actions/BrandsActions'

type BrandsStateType = {
  all: Object,
  current: string | null,
}

const initialState: BrandsStateType = {
  all: {},
  current: null,
}

const brandsReducer = (
  state: BrandsStateType = initialState,
  action: BrandsActionType
): BrandsStateType => {
  switch (action.type) {
    case BrandsAction.SET:
      return {
        ...state,
        all: action.brands,
      }
    case BrandsAction.SET_CURRENT:
      return {
        ...state,
        current: action.name,
      }
    case BrandsAction.CLEAR:
      return initialState
    default:
      return state
  }
}

export default brandsReducer

export type BrandsState = BrandsStateType
