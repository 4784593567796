import { StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 100,
    paddingTop: 30,
    width: '100%',
  }
});

export default styles;