import Api from './Api'

class ConfigApi extends Api {
  static resource: string = 'config'

  static get(): any {
    return super.get(`${ConfigApi.resource}`).then((data: Object): any => {
      return data
    })
  }
}

export default ConfigApi
