import type { ReactNode } from 'react';
import React from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import type { Action, Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { css } from 'aphrodite/no-important';

import { setCurrentBrand } from '../../redux/actions/BrandsActions';
import { selectCurrentBrand } from '../../redux/selectors/configurationSelectors';

import { TextHeader } from '../../components/common';

import {
  makeCurrentBrandCarProtectProductsSelector,
  selectCurrentCarProtectProduct,
} from '../../redux/selectors/carProtectSelectors';
import styles from './CarProtectProductView.styles';
import { CarProtectProdcut } from '../../types/CarProtectProdcut';
import CarProtectInformations from '../../components/products/CarProtectInformations';
import CarProtectContact from '../../components/products/CarProtectContact';
import CarProtectDownloads from '../../components/products/CarProtectDownloads';

type CarProtectProductViewProps = {
  currentBrand: string;
  carProtectProduct: CarProtectProdcut;
};

const CarProtectProductView = ({
  currentBrand,
  carProtectProduct,
}: CarProtectProductViewProps): ReactNode => {
  const { title, downloads, info, contact } = carProtectProduct;

  return (
    <div
      className={`o-page-wrap  o-page-wrap--xsmall ${css(styles.container)}`}>
      <Helmet>
        <title>{`Oferta Pakietów Usług Serwisowych ${currentBrand.toUpperCase()}`}</title>
      </Helmet>

      <TextHeader type={'h1'}>{title}</TextHeader>
      {info && <CarProtectInformations info={info} />}
      {contact && <CarProtectContact contact={contact} />}
      {downloads && <CarProtectDownloads downloads={downloads} />}
    </div>
  );
};

const mapStateToProps = (state: any): any => {
  const selected = createStructuredSelector({
    currentBrand: selectCurrentBrand,
    carProtectProduct: selectCurrentCarProtectProduct,
  })(state);

  return {
    carProtectProducts: makeCurrentBrandCarProtectProductsSelector(state),
    ...selected,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): any => {
  const actions = {
    setCurrentBrand,
  };
  return bindActionCreators(actions, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CarProtectProductView);
