import { CarProtectProdcut } from '../../types/CarProtectProdcut';
import { CarProtectAction } from '../constants/CarProtectAction';

type ClearCarProtectActionType = {
  type: typeof CarProtectAction.CLEAR;
};

type SetCarProtectActionType = {
  type: typeof CarProtectAction.SET;
  products: Object[];
};

type SetCurrentCarProtectActionType = {
  type: typeof CarProtectAction.SET_CURRENT;
  product: CarProtectProdcut;
};

export type CarProtectActionType =
  | ClearCarProtectActionType
  | SetCurrentCarProtectActionType
  | SetCarProtectActionType;

const clearCarProtect = (): CarProtectActionType => ({
  type: CarProtectAction.CLEAR,
});

const setCarProtect = (
  products: CarProtectProdcut[]
): CarProtectActionType => ({
  type: CarProtectAction.SET,
  products,
});

const setCurrentCarProtect = (
  product: CarProtectProdcut
): CarProtectActionType => ({
  type: CarProtectAction.SET_CURRENT,
  product,
});

export { clearCarProtect, setCarProtect, setCurrentCarProtect };
