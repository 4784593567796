const OfferAction = {
  CLEAR: 'OFFER_CLEAR',
  SET_MODEL: 'OFFER_SET_MODEL',
  SET_OPTION: 'OFFER_SET_OPTION',
  SET_PRODUCT: 'OFFER_SET_PRODUCT',
  SET_TYPE: 'OFFER_SET_TYPE',
  SET_YEAR: 'OFFER_SET_YEAR',
  SET_ENGINE: 'OFFER_SET_ENGINE',
}

export { OfferAction }
