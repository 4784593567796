import React from 'react'
import type { ReactNode } from 'react'

import classNames from 'classnames'

type FooterProps = {
  setCurrentBrand: Function,
}

const Footer = ({ setCurrentBrand }: FooterProps): ReactNode => {
  const renderHeaderItem = (title: string, onClick: Function): ReactNode => (
    <div className='c-footer-nav__column js-footer-accordion js-is-ready'>
      <div className='c-footer-nav__title js-footer-accordion__title'>
        <h4
          className={classNames({
            'c-footer-nav__title-label': true,
            'is-button': onClick,
          })}>
          {title}
        </h4>
        <button
          className={classNames({
            'c-footer-nav__title-label js-footer-accordion__title-label': true,
            'is-button': !onClick,
          })}
          onClick={onClick}
          type='button'
          aria-expanded='false'>
          {title}
        </button>
      </div>
    </div>
  )

  return (
    <footer className='c-footer' style={{ textAlign: 'center' }}>
      <div className='c-footer__module  c-footer__module--meta-nav'>
        <div className='o-page-wrap'>
          <nav className={'c-footer-nav'}>
            <div className='c-footer-nav__columns'>
              {renderHeaderItem('VW OSOBOWE', (): void =>
                setCurrentBrand('vw')
              )}
              {renderHeaderItem('VW DOSTAWCZE', (): void =>
                setCurrentBrand('vwd')
              )}
              {renderHeaderItem('AUDI', (): void => setCurrentBrand('audi'))}
              {renderHeaderItem('ŠKODA', (): void => setCurrentBrand('skoda'))}
              {renderHeaderItem('SEAT', (): void => setCurrentBrand('seat'))}
              {renderHeaderItem('CUPRA', (): void => setCurrentBrand('cupra'))}
            </div>
          </nav>
        </div>
      </div>
      <div className='c-footer__module  c-footer__module--footnotes'>
        <div className='o-page-wrap'>
          <div className='c-footer-footnotes'>
            <p>
              Pod nazwą Volkswagen Financial Services oferowane są usługi
              bankowe (przez Volkswagen Bank GmbH Sp. z o.o. Oddział w Polsce),
              usługi leasinowe i mobilność (przez Volkswagen Financial Services
              Polska Sp. z o.o.) oraz usługi ubezpieczeniowe (przez Volkswagen
              Serwis Ubezpieczeniowy Sp. z o.o jak agenta ubezpieczeniowego).
            </p>
            <p>© 2024 Volkswagen Serwis Ubezpieczeniowy Sp. z o.o.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
