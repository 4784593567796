const formatPrice = (price: number | string): string => {
  if (isNaN(price)) {
    return price;
  }
  const money = formatMoney(price);
  if (money === '-') {
    return money;
  }
  return `${money} zł`;
};

const formatMoney = (
  amount: number,
  decimalCount: number,
  decimal: string = '.',
  thousands: string = ' '
): string => {
  try {
    if (amount === null || amount === undefined) {
      return '-';
    }
    decimalCount = Math.abs(decimalCount);
    const parts = amount?.toString().split('.');
    if (!decimalCount && parts.length === 2) {
      decimalCount = parts[1].length;
    }
    decimalCount = isNaN(decimalCount) ? 0 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
  return (amount || '-').toString();
};

export { formatPrice };
