import React, { memo } from 'react';
import type { ReactNode } from 'react';

import { StyleSheet, css } from 'aphrodite/no-important';
import classNames from 'classnames';

import StyleGuide from '../../styles/StyleGuide';
import { Text, TextHeader } from '../common/';

type ImportantInformationsPresenterProps = {
  darkBackground?: boolean;
  data: Object;
  brand: string;
};

const ImportantInformationsPresenter = ({
  brand,
  darkBackground = false,
  data = {},
}: ImportantInformationsPresenterProps): ReactNode => {
  const renderMessage = (text: string, idx: number): ReactNode => (
    <div className={css(styles.item)} key={text}>
      <div className={css(styles.itemIcon)}>
        <span style={{ width: '100%', textAlign: 'center' }}>{'>'}</span>
      </div>
      <Text>{text}</Text>
    </div>
  );

  return (
    <div
      className={classNames({
        [css(styles.dark)]: darkBackground,
        [css(styles.container)]: true,
      })}>
      <div className={'o-page-wrap  o-page-wrap--xsmall'}>
        <TextHeader className={css(styles.header)} type={'h2'}>
          {'Ważne informacje i wyłączenia'}
        </TextHeader>
        <TextHeader>{data.subtitle}</TextHeader>
        {data.messages &&
          data.messages.map(
            (item: Object): ReactNode => renderMessage(item.message)
          )}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 32,
    paddingBottom: 70,
  },
  dark: {
    backgroundColor: StyleGuide.Colors.gray,
  },
  item: {
    display: 'flex',
    alignItems: 'flex-start', // Corrected value
    justifyItems: 'top',
  },
  header: {
    paddingBottom: 16,
    textAlign: 'center',
  },
  itemIcon: {
    minWidth: 16,
    minHeight: 16,
    width: 16,
    height: 16,
    backgroundColor: StyleGuide.Colors.secondary,
    marginTop: 6,
    marginRight: 9,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    fontWeight: 800,
    fontSize: 16,
  },
});

export default memo(ImportantInformationsPresenter);
