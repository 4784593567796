import { combineReducers } from 'redux'
import auth, { AuthState } from './authReducer'
import brands, { BrandsState } from './brandsReducer'
import config, { ConfigState } from './configReducer'
import offer, { OfferState } from './offerReducer'
import carProtect, { CarProtectState } from './carProtectReducer'

const reducers = {
  auth,
  brands,
  config,
  offer,
  carProtect,
}

export type RootState = {
  auth: AuthState
  brands: BrandsState
  config: ConfigState
  offer: OfferState
  carProtect: CarProtectState
}

export default combineReducers(reducers)